import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
@Pipe({
    name: 'monTranslateReadability',
})
export class TranslateReadabilityPipe implements PipeTransform {
    DICTIONARY: { [key: string]: { [key: string]: string } } = {
        flesch_kincaid_re: {
            1: 'Below 5th grade',
            5: '5th grade',
            6: '6th grade',
            7: '7th grade',
            8: '8th to 9th grade',
            10: '10th to 12th grade',
            15: 'College',
            17: 'College graduate',
            na: 'No readability score',
            test_name: 'Flesch Kincaid',
        },
        lix: {
            'very easy': 'Very easy',
            easy: 'Easy',
            medium: 'Medium',
            hard: 'Hard',
            'very hard': 'Very hard',
            na: 'No readability score',
            test_name: 'LIX',
        },
    };
    constructor (private translateService: TranslateService) {}
    transform (word: string, type?: string): string {
        if (!type) {
            return this.translateService.getString(this.DICTIONARY[word].test_name);
        }
        return this.translateService.getString(this.DICTIONARY[type][word]);
    }
}
