import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import {
    FormsBuilderModule,
    LayoutModule,
    TranslateModule,
    IconsModule,
    DialogModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormBackendAdminCustomerPlanComponent } from './plan.component';

@NgModule({
    imports: [CommonModule, AngularFormModule, IconsModule, LayoutModule, TranslateModule, FormsBuilderModule, DialogModule],
    exports: [FormBackendAdminCustomerPlanComponent],
    declarations: [FormBackendAdminCustomerPlanComponent],
    providers: [],
})
export class PlanModule {}
