import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ParamModule } from '@monsido/core/param/param.module';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { BaseLayoutModule } from '@layout/layout.module';
import { ServicesModule } from '@monsido/services/services.module';
import { CRAWL_QUEUE_STATES } from './crawl-queue.state';
import { CrawlQueueComponent } from './crawl-queue.component';
import { CommonModule } from '@angular/common';
import { LayoutModule, TranslateModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MomentModule } from 'ngx-moment';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { TableModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CrawlQueueService } from '@monsido/pages/admin/crawl-queue/crawl-queue.service';
import { TableSearchModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { SearchService } from '@monsido/pages/backend-admin/data-privacy-checks/helper/search.service';

@NgModule({
    imports: [
        ParamModule,
        ApiModule,
        BaseLayoutModule,
        LayoutModule,
        UIRouterModule.forChild({ states: CRAWL_QUEUE_STATES }),
        ServicesModule,
        CommonModule,
        MomentModule,
        ConfirmModule,
        TableModule,
        NgbTooltipModule,
        TranslateModule,
        TableSearchModule,
        IconsModule,
    ],
    exports: [],
    declarations: [CrawlQueueComponent],
    providers: [CrawlQueueService, SearchService]
})
export class CrawlQueueModule {}
