import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { OauthService } from '@monsido/oauth/oauth.service';
import { TableService } from '@monsido/pages/admin/users/table/table.service';
import { CustomerService } from '@monsido/services/customer/customer.service';

@Component({
    selector: 'mon-admin-table-users',
    templateUrl: 'table.html',
})
export class AdminTableUsersComponent implements OnInit {
    path: string;
    @Input() showCustomer: boolean = false;
    @Input() isAdmin: boolean = false;
    @Input() users: User[];
    @Output() getPage: EventEmitter<void> = new EventEmitter();

    constructor (private oauthService: OauthService, private tableService: TableService, private customerService: CustomerService) {
        this.path = this.oauthService.getApiPath();
    }

    ngOnInit (): void {}

    deleteUser (user: User): void {
        this.tableService.deleteUser(user).then(() => {
            this.getPage.emit();
        });
    }

    editUser (e: MouseEvent, user: User): void {
        e.preventDefault();
        this.tableService.editUser(user, (forceUpdate) => {
            if (forceUpdate) {
                this.getPage.emit();
            }
        });
    }
    // using any here do to unknown can't have indexes so data.user.id will be bad
    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSudo (data: Record<string, any>): void {
        this.customerService.sudo(data.customerId, data.user.id);
    }
}
