import { CustomerService } from '@monsido/services/customer/customer.service';
import { User } from '@monsido/modules/models/api/user';
import { Injectable } from '@angular/core';
import { DialogService, TranslateService, ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormUserComponent } from '@monsido/forms/user/user.component';

@Injectable()
export class TableService {
    constructor (
        private customerService: CustomerService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private dialogService: DialogService,
    ) {}

    deleteUser (user: User): Promise<void> {
        if (!user.isEditable()) {
            this.toastService.warning(this.translateService.getString('This user is missing a customer agreement'));
            return Promise.reject(Error(this.translateService.getString('This user is missing a customer agreement')));
        } else {
            const agreements = user.user_agreements.filter((agreement) => {
                return agreement.account && agreement.account.type === 'Customer';
            });
            return this.customerService.deleteUser(agreements[0].account.id, user.id).then(() => {
                this.toastService.success(this.translateService.getString('User deleted'));
            });
        }
    }

    editUser (user: User, cb: (...args: unknown[]) => void): void {
        if (!user.isEditable()) {
            this.toastService.warning(this.translateService.getString('This user is missing a customer agreement'));
        } else {
            const agreements = user.user_agreements.filter((agreement) => {
                return agreement.account && agreement.account.type === 'Customer';
            });

            const dialogRef = this.dialogService.open(FormUserComponent, {
                classes: 'mon-dialog-size-sm',
                defaultWrapper: false,
                formConfirmPromptParentSelector: '#common-dialog-wrapper',
                cb,
            });

            dialogRef.componentInstance.user = Object.assign({}, user);
            dialogRef.componentInstance.customerId = agreements[0].account.id;
        }
    }
}
