<table class="table table-hover">
    <thead>
    <tr>
        <th  translate>User</th>
        <th  translate *ngIf="showCustomer">Customer</th>
        <th translate>Partner</th>
        <th  translate>Latest login</th>
        <th  translate>Created at</th>
        <th class="text-right" translate></th>
        <th  class="text-right" translate *ngIf="['admin', 'reseller_admin', 'reseller'] | monRole"></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let user of users">
        <tr>
            <td>
                <mon-email-avatar [user]="user" *ngIf="user.first_name"></mon-email-avatar>
            </td>
            <td *ngIf="showCustomer">
                <ul class="list-unstyled">
                    <ng-container *ngFor="let agreement of user.user_agreements">
                        <li ng-repeat="agreement in user.user_agreements track by $index"
                            *ngIf="agreement.account.type == 'Customer'">
                            <a
                                class="borderless btn btn-link inner-none"
                                uiSref="base.admin.customers.show"
                                [uiParams]="{customer_id: agreement.account.id}"
                                data-test="user-customer-agreement"
                                >
                                {{agreement.account.name}}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </td>
            <td>
               {{ user.getResellerAgreementName() }}
            </td>
            <td>
                <mon-user-date [twoLines]="true" [date]="user.last_login_at"></mon-user-date>
            </td>
            <td>
               <mon-user-date [twoLines]="true" [date]="user.created_at"></mon-user-date>
            </td>
            <td class="text-right">
                <mon-table-dropdown>
                    <li ngbDropdownItem *ngIf="user.getCustomerCount() === 1 && (['admin', 'reseller_admin', 'reseller'] | monRole)"
                        role="menuitem" data-test="action-button">
                        <a [href]="path + '/sudo/' + user.id + '?customer_id=' + user.getFirstCustomerId()" target="_blank" aria-describedby="linkOpensInNewTab" data-test="sudo-classic">
                            <mon-icon [icon]="['USERS', 'SUDO']"></mon-icon>
                            <span class="ml-1" translate>Sudo classic</span>
                        </a>
                    </li>
                    <li ngbDropdownItem role="menuitem">
                        <a href (click)="editUser($event, user)" data-test="edit-button">
                            <mon-icon [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                            <span class="ml-1" translate>Edit</span>
                        </a>
                    </li>
                    <li ngbDropdownItem role="menuitem">
                        <a monConfirm="{{'Are you sure you want to delete this user?' | translate}}"
                           (monConfirmAction)="deleteUser(user)"
                           class="cursor-pointer" data-test="delete-button">
                           <mon-icon [icon]="['ACTIONS', 'DELETE']"></mon-icon>
                            <span class="ml-1" translate>Delete</span>
                        </a>
                    </li>
                </mon-table-dropdown>
            </td>
            <td class="text-right">
                <mon-sudo-button [user]="user" (sudoEvent)="onSudo($event)" data-test="sudo-button"></mon-sudo-button>
            </td>
        </tr>
    </ng-container>
    </tbody>
</table>

