export const PERFORMANCE = {
    PERFORMANCE_FREQUENCIES: [
        {
            name: 'Four times a day',
            value: 6,
        },
        {
            name: 'Twice a day',
            value: 12,
        },
        {
            name: 'Once a day',
            value: 24,
        },
        {
            name: 'Once a week',
            value: 168,
        },
    ],
};
