import { AdditionalInternalUrls } from 'types/domain';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ControlContainer, NgForm } from '@angular/forms';

interface OperatorOption {
    value: string;
    name: string;
}

@Component({
    selector: 'mon-form-domain-scan-internal-urls',
    templateUrl: 'internal-urls.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainScanInternalUrlsComponent implements OnInit {
    @Input() monInternalUrls: AdditionalInternalUrls[];

    operators: OperatorOption[] = [];

    constructor (private translateService: TranslateService) {}

    ngOnInit (): void {
        this.operators = [
            {
                value: 'contains',
                name: this.translateService.getString('Contains'),
            },
            {
                value: 'starts_with',
                name: this.translateService.getString('Starts with'),
            },
            {
                value: 'regex',
                name: this.translateService.getString('Regex'),
            },
        ];

        if (this.monInternalUrls.length === 0) {
            this.addUrl();
        }
    }

    addUrl (): void {
        this.monInternalUrls.push({
            operator: 'starts_with',
            value: '',
        });
    }

    removeUrl (index: number): void {
        this.monInternalUrls.splice(index, 1);
    }
}
