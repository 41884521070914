import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { CommonModule } from '@angular/common';
import { ConfirmModule, DialogService, LayoutModule, TableModule, ToastService, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Reseller } from '@monsido/modules/models/api/interfaces/user.interface';
import { GetAllResellersParams, ResellersRepo } from '../../../services/api/backend_admin/resellers-repo';
import { ParamService } from '@monsido/core/param/param.service';
import { HttpHeaders } from '@angular/common/http';
import {
    FormBackendAdminResellerComponent,
} from '../../../forms/backend_admin/form-backend-admin-reseller/form-backend-admin-reseller.component';
import { cloneDeep } from 'lodash';
import { MonIconsPipe } from '../../../filters/mon-icons.pipe';
import { UIRouterModule } from '@uirouter/angular';
import { PromptModule } from '../../../../ng2/external/prompt/prompt.module';

@Component({
    selector: 'resellers',
    templateUrl: 'resellers.html',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TableModule,
        TranslateModule,
        ConfirmModule,
        MonIconsPipe,
        UIRouterModule,
        PromptModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ResellersComponent extends BaseApiComponent implements OnInit {
    resellers: Reseller[] = [];

    constructor (
        urlService: UrlService,
        private resellersRepo: ResellersRepo,
        private translateService: TranslateService,
        private notificationService: ToastService,
        private paramService: ParamService,
        private dialogService: DialogService,
        private cdRef: ChangeDetectorRef,
    ) {
        super(urlService);
    }

    ngOnInit (): void {
        this.loadLocations();
    }

    stopPropagation (event:Event): void {
        event.stopPropagation();
    }

    async getPage (): Promise<void> {
        this.paramService.setParams({
            search: this.search,
            page_size: this.pageSize,
            page: this.page,
        });
        await this.getResellers();
    }

    createReseller (): void {
        const dialogRef = this.dialogService.open(FormBackendAdminResellerComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                this.getPage();
            },
        });

        dialogRef.componentInstance.reseller = {} as Reseller;
    }

    editReseller (reseller: Reseller): void {
        const dialogRef = this.dialogService.open(FormBackendAdminResellerComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                this.getPage();
            },
        });

        dialogRef.componentInstance.reseller = cloneDeep(reseller);
    }

    onDeleteReseller (result: string) {
        return (reseller: Reseller): void => {
            const { id } = reseller;
            if (Number(id) === Number(result)) {
                this.resellersRepo.destroy(id, {}, new HttpHeaders({})).then(() => {
                    this.notificationService.success(this.translateService.getString('Partner is deleted'));
                    this.getPage();
                });
            } else {
                this.notificationService.error(this.translateService.getString('Wrong Answer'));
            }
        };
    }

    private async getResellers (): Promise<void> {
        const params: GetAllResellersParams = {
            page: this.page,
            page_size: this.pageSize,
        };

        if (this.search) {
            params.search = this.search;
        }

        this.loading = true;
        this.cdRef.markForCheck();

        try {
            this.resellers = await this.resellersRepo.getAll(params, new HttpHeaders({}));
        } finally {
            this.loading = false;
        }
        this.cdRef.markForCheck();
    }
}
