<div class="form-domain-features-container">
<div ngForm="domainFeatureFieldsForm" #domainFeatureFieldsForm="ngForm">
    <mon-form-field-select
        *ngIf="monCustomer.plan_traits.accessibility"
        [(model)]="monDomain.features.accessibility"
        [monName]="'accessibility'"
        [monOptions]="accessibilities"
        monLabel="{{ 'Accessibility' | translate }}"
        [monReturnValue]="'accessibility_guideline_id'"
        monPlaceholder="{{ 'None' | translate }}"
        [monAllowClear]="true">
    </mon-form-field-select>

    <mon-form-field-select
        *ngIf="monCustomer.plan_traits.clarity_pdf_accessibility"
        [(model)]="monDomain.features.clarity_guideline"
        [monName]="'clarity_guideline'"
        name="clarity_guideline"
        [monOptions]="clarityOptions"
        monLabel="{{ 'PDF Accessibility' | translate }}"
        [monReturnValue]="'guideline_id'"
        [monAllowClear]="true"
        monPlaceholder="{{ 'None' | translate }}">
    </mon-form-field-select>

    <mon-form-field-select
        *ngIf="monCustomer.plan_traits.readability"
        [monName]="'readability'"
        [(model)]="monDomain.features.readability_test"
        [monOptions]="readabilities"
        monLabel="{{ 'Readability' | translate }}"
        [monReturnValue]="'readability_test_id'"
        monPlaceholder="{{ 'None' | translate }}"
        [monAllowClear]="true">
    </mon-form-field-select>

    <div
        *ngIf="monCustomer.plan_traits.readability && monDomain.features.readability_test">
        <mon-form-field-input
            [(ngModel)]="monDomain.features.readability_test_min_words"
            monPlaceholder="{{'Choose the minimum amount of words to scan for readability on a page' | translate}}"
            required
            #readability_test_min_words="ngModel"
            name="features_readability_miminum_words_count"
            type="number"
            monLabel="{{'Minimum words' | translate}}">
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="readability_test_min_words.control.touched && readability_test_min_words.control.invalid"
            [errors]="readability_test_min_words.control.errors">
        </mon-form-field-errors>
    </div>

    <mon-form-field-checkbox
        *ngIf="monCustomer.plan_traits.statistics"
        [(model)]="monDomain.features.statistics"
        [monName]="'statistics'"
        monLabel="{{'Statistics' | translate}}">
    </mon-form-field-checkbox>

    <mon-form-field-checkbox *ngIf="(['admin'] | monRole)"
        [(model)]="monDomain.scan.scan_cpr"
        [monName]="'scan_cpr'"
        monLabel="{{'Scan cpr' | translate}}">
    </mon-form-field-checkbox>

    <mon-form-field-checkbox
        [(model)]="monDomain.scan.scan_documents"
        [monName]="'scan_documents'"
        monLabel="{{'Scan documents' | translate}}">
    </mon-form-field-checkbox>

    <mon-form-field-checkbox
            *ngIf="monIsBackendAdmin && monCustomer.plan_traits.data_protection"
            [(model)]="monDomain.features.data_protection"
            [monName]="'data_protection'"
            monLabel="{{'Data Protection' | translate}}">
    </mon-form-field-checkbox>

    <div class="form-group monsido-addon-settings-group" *ngIf="monCustomer.plan_traits.page_assist">
        <label class="col-form-label col-sm-8 pt-1"
            for="domain_page_assist" translate>
            Monsido PageAssist
        </label>
        <div class="col-sm-40">
            <div class="d-flex inner-t-mini">
                <div>
                    <mon-form-field-checkbox
                        id="domain_page_assist"
                        [monName]="'monsido_page_assist'"
                        [(model)]="monDomain.features.page_assist" ></mon-form-field-checkbox>
                </div>
                <div>
                    <div class="setting-button">
                        <a role="button"
                            attr.aria-disabled="{{!monDomain.features.page_assist}}"
                            [ngClass]="{'mon-disabled': !monDomain.features.page_assist}"
                            (click)="monDomain.features.page_assist && openPageAssist()"
                            translate>
                            Open PageAssist Settings
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group monsido-addon-settings-group" *ngIf="monCustomer.plan_traits.page_fix">
        <label class="col-form-label col-sm-8 pt-1"
               for="monsido_page_fix" translate>
               Monsido PageCorrect
        </label>
        <div class="col-sm-40">
            <div class="d-flex">
                <div>
                    <mon-form-field-checkbox
                        id="monsido_page_fix"
                        [monName]="'monsido_page_fix'"
                        [(model)]="monDomain.features.page_fix"></mon-form-field-checkbox>
                </div>
                <div>
                    <div class="setting-button">
                        <a role="button"
                            attr.aria-disabled="{{!monDomain.features.page_fix}}"
                            [ngClass]="{'mon-disabled': !monDomain.features.page_fix}"
                            (click)="monDomain.features.page_fix && openPageFix()"
                            translate>
                            Open PageCorrect Settings
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mon-form-field-checkbox
        *ngIf="monCustomer.plan_traits.cookie_banner"
        [(model)]="monDomain.features.cookie_banner"
        [monName]="'cookie_banner'"
        monLabel="{{'Consent Manager' | translate}}">
    </mon-form-field-checkbox>
</div>
</div>
