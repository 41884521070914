import { PlanInterface } from './interfaces/plan.interface';
import { UniqueTraitsInterface } from './interfaces/uniqueTraits.interface';
export class Plan implements PlanInterface {
    id: number;
    name: string;
    code: string;
    group: number;
    price: Record<string, { [key: string]: number }> = { '1m': {}, '12m': {} };
    unique_traits: UniqueTraitsInterface = {
        nag_for_upgrade: false,
        statistics: false,
        accessibility: false,
        mobile_guidelines: false,
        paying: false,
        readability: false,
        clarity_pdf_accessibility: false,
        custom_scan_interval: false,
        heartbeat: true,
        domain_group_statistics: false,
        source_code_excludes: false,
        performance_tracking_on_demand_scan: false,
        multiple_user_agreements: false,
        on_demand_scans: false,
        max_scanned_pages: 0,
        max_domains: 0,
        scan_interval: 0,
        clarity_pdf_remediation: false,
        page_fix: false,
        page_assist: false,
        closed_caption: false,
        heatmap_scroll: false,
        heatmap_click: false,
        heatmap_movement: false,
        heatmaps_traffic_percentage: 0,
        heatmaps_tracked_number_of_pages: 0,
        heatmaps_monthly_sessions: 0,
        heatmaps_monthly_page_sessions: 0,
        image_metadata: false,
        api_users: false,
        single_page_scan_any_url: false,
        performance_tracking: false,
        performance_tracking_max_pages: 0,
        performance_tracking_max_page_profiles: 0,
        performance_tracking_max_profiles: 0,
        performance_tracking_allowed_frequencies: [],
        data_protection: false,
        uptime_intervals: [],
        spell_check_engine_priority: [],
        skip_on_hold: false,
        cookie_banner: false,
        monsido_score: false,
        inventory_all: false,
        seo_all: false,
        report_center_all: false,
        history_center_all: false,
        qa_links: false,
        qa_spellcheck: false,
        general_chat_support: false,
        general_book_training: false,
        general_shortcuts: false,
        data_export: false,
        policies_all: false,
        prioritized_content_all: false,
        accessibility_summary: false,
        account_settings: false,
        dashboard_important_notifications: false,
        page_details: false,
        qa_summary: false,
        quick_search: false,
        script_setup_guide: false,
    };
    active: boolean;
    type: string;
    created_at: string;
    updated_at: string;
    constructor (plan?: PlanInterface) {
        Object.assign(this, plan);
    }
}
