import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BasicAuthLoginType } from 'types/domain';
import { TranslateService, MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';

@Component({
    selector: 'mon-form-domain-scan-login-types-basic-auth',
    templateUrl: 'basic-auth.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainScanLoginTypesBasicAuthComponent implements OnInit {
    @Input() monLogin: BasicAuthLoginType;
    @Input() monUrl: string;
    @Input() monForm: NgForm;
    @Input() monRequestChecker: boolean = false;

    validHostname: boolean[] = [];

    constructor (
        private monPromptService: MonPromptService,
        private translateService: TranslateService,
        private globalHelperService: GlobalHelperService,
    ) {}

    ngOnInit (): void {
        this.monLogin = this.monLogin || {
            type: 'basic_auth',
        };
        if (!this.monLogin.basic_auth) {
            this.monLogin.basic_auth = {
                username: '',
                password: '',
                hosts: [''],
            };
        }

        if (!Array.isArray(this.monLogin.basic_auth.hosts)) {
            this.monLogin.basic_auth.hosts = [''];
            if (typeof this.monUrl === 'string') {
                this.monLogin.basic_auth.hosts = [this.monUrl.replace(/(^\w+:|^)\/\//, '')];
            }
        }

        this.validHostname = this.monLogin.basic_auth.hosts.map((hostName) => {
            return hostName && this.globalHelperService.isValidHostname(hostName);
        });
    }

    addHost (): void {
        this.monLogin.basic_auth.hosts.push('');
    }

    removeHost (index: number): void {
        this.monPromptService.confirm(this.translateService.getString('Are you sure you want to remove this host?'), {
            size: 'md',
            callback: () => {
                this.monLogin.basic_auth.hosts.splice(index, 1);
            },
        });
    }

    isValidHostname (hostnames: string[], index: number): void {
        this.validHostname[index] = hostnames[index] && this.globalHelperService.isValidHostname(hostnames[index]);
    }

    trackById (index: number): number {
        return GlobalHelperService.trackById(index);
    }
}
