<div ngModelGroup="plan">
    <div class="row" id="backend-admin-customer-plan">
        <div class="col-sm-offset-2 col-sm-40">
            <h5 translate>Subscription start date*</h5>
            <mon-form-field-datepicker
              datePickerInputName="subscription_start"
              [ngRequired]="true"
              [(model)]="customer.subscription.subscription_start_at"
              (modelChange)="setDate($event)"></mon-form-field-datepicker>
            <h5 translate>Subscription</h5>
            <mon-form-field-radio-new-line returnValue="id"
                                           name="customer.subscription.plan_id"
                                           monName="customer.subscription.plan_id"
                                           [options]="planOptions"
                                           [(model)]="customer.subscription.plan_id"
                                           (modelChange)="onSelectPlan()">
            </mon-form-field-radio-new-line>
            <h5 translate>Account status</h5>
            <mon-form-field-checkbox monName="customer.account_suspended"
                                     [(model)]="customer.account_suspended"
                                     monLabel="{{'Account suspended' | translate}}">
            </mon-form-field-checkbox>

            <h5 translate>Browser service in use</h5>
            <mon-form-field-select monName="browser_service"
                                   [(model)]="customer.settings.js_rendering_service"
                                   [monOptions]="browserServiceOptions"
                                   monLabel="{{ 'Browser service' | translate }}"
                                   monPlaceholder="{{ 'Browser service' | translate }}"
                                   monReturnValue="value"
                                   (modelChange)="changeBrowserService()">
            </mon-form-field-select>
        </div>
    </div>
</div>
