import { CrawlInterface, Customer, Domain, OnHoldInfo } from './interfaces/crawl-queue.interface';

export class CrawlQueue implements CrawlInterface {
    queued_at: string | Date;
    title: string;
    estimated_page_count?: number;
    new_links?: null;
    scanned_count?: number;
    dead_count?: number;
    page_count?: number;
    state: string;
    state_ref?: string;
    details: string;
    crawling?: null;
    support_link?: string;
    domain: Domain;
    customer: Customer;
    on_hold_info?: OnHoldInfo;
    crawler_info?: null;
    constructor (crawlQueue: CrawlInterface) {
        Object.assign(this, crawlQueue);
        this.queued_at = new Date(this.queued_at);
    }
}
