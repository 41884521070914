import { AccessibilityChecksHelperService } from 'app/blocks/helpers/accessibility/accessibility-checks-helper.service';

export type AccessibilityCheckType = {
    article_url: string
    check_id: number
    description: string
    difficulty: number
    fail_examples: string
    following_check: Record<string, unknown>
    friendly_name: string
    how_to_repair: string
    html_element: string
    id: number
    impact: string
    locations: string[]
    mobile: boolean
    name: string
    others: Record<string, unknown>[]
    pass_examples: string
    previous_check: { check_id: number, name: string }
    quick_guide: string
    responsibilities: string[]
    short_description: string
    tags: string[]
}

export type ImpactType = { name: string, value: string }
export type LevelType = { name: string, value: number };
export type ResposibilityType = { name: string, value: string }
export type TagType = { name: string, value: string, group: string }

export class AccessibilityCheckModel implements AccessibilityCheckType {
    article_url: string;
    check_id: number;
    description: string;
    difficulty: number;
    fail_examples: string;
    following_check: Record<string, unknown>;
    friendly_name: string;
    how_to_repair: string;
    html_element: string;
    id: number;
    impact: string;
    locations: string[] = [];
    mobile: boolean;
    name: string;
    others: Record<string, unknown>[];
    pass_examples: string;
    previous_check: { check_id: number, name: string };
    quick_guide: string;
    responsibilities: string[];
    short_description: string;
    tags: string[];
    impactList: ImpactType[];
    levelList: LevelType[];
    responsibilityList: ResposibilityType[];
    tagList: TagType[];
    other_headline?: string;


    // TODO we need to look into if we can inject the services directly instead of passing them as params - https://optimere.atlassian.net/browse/MON-5432
    constructor (
        params: AccessibilityCheckType,
        private accessibilityChecksHelperService: AccessibilityChecksHelperService,
    ) {
        const check: Record<string, unknown> = {};
        check.article_url = params.article_url;
        check.check_id = params.check_id;
        check.description = params.description;
        check.difficulty = params.difficulty;
        check.fail_examples = params.fail_examples;
        check.following_check = params.following_check;
        check.friendly_name = params.friendly_name;
        check.how_to_repair = params.how_to_repair;
        check.html_element = params.html_element;
        check.id = params.id;
        check.impact = params.impact;
        check.locations = params.locations;
        check.mobile = params.mobile;
        check.name = params.name;
        check.others = params.others;
        check.pass_examples = params.pass_examples;
        check.previous_check = params.previous_check;
        check.responsibilities = params.responsibilities;
        check.short_description = params.description;
        check.tags = params.tags;
        check.quick_guide = params.quick_guide;

        check.impactList = this.accessibilityChecksHelperService.getImpactLevels();
        check.levelList = this.accessibilityChecksHelperService.getLevels();
        check.responsibilityList = this.accessibilityChecksHelperService.getResponsibilities();
        check.tagList = this.accessibilityChecksHelperService.getTags();

        return check as unknown as AccessibilityCheckModel;
    }
}
