import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { Reseller, UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';
import { LayoutModule, DialogService, MonPromptService, ToastService, TranslateService, TableModule, TranslateModule, PromptModule, MonAvatarModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { UrlService } from '@uirouter/core';
import { FormBackendAdminUserComponent } from 'app/forms/backend_admin/user/form-backend-admin-user/form-backend-admin-user.component';
import { cloneDeep } from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { ConfirmModule } from '@monsido/confirm/confirm.module';

@Component({
    selector: 'mon-backend-admin-reseller-user-tab',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TableModule,
        TranslateModule,
        PromptModule,
        MonAvatarModule,
        ConfirmModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './backend-admin-reseller-user-tab.component.html',
})
export class BackendAdminResellerUserTabComponent extends BaseApiComponent implements OnInit {
    @Input() reseller: Reseller;
    resellers: Reseller[];
    selectedUsers: UserInterface[];
    users: CollectionInterface<UserInterface>;
    loading: boolean = false;

    constructor (
        private resellersRepo: ResellersRepo,
        private toastService: ToastService,
        private promptService: MonPromptService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private cdr: ChangeDetectorRef,
        $location: UrlService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.resellers = [];
        this.users = [];
        this.selectedUsers = [];
        this.getPage();
    }

    getPage (): void {
        if (this.reseller) {
            const params = {
                page: this.page,
                page_size: this.pageSize,
                search: this.search,
            };
            this.loading = true;
            this.resellersRepo.getAllUsers(this.reseller.id, params, undefined).then(
                (data) => {
                    this.users = data;
                    this.loading = false;
                },
                (res) => {
                    this.loading = res.status === -1 && res.xhrStatus === 'abort';
                },
            )
                .finally(() => this.cdr.detectChanges());
        }
    }

    create (): void {
        const dialogRef = this.dialogService.open(
            FormBackendAdminUserComponent,
            {
                defaultWrapper: false,
                classes: 'mon-dialog-size-sm',
                cb: () => {
                    this.getPage();
                },
            },
        );
        dialogRef.componentInstance.user = {} as UserInterface;
        dialogRef.componentInstance.reseller = this.reseller;
    }

    edit (user: UserInterface): void {
        const dialogRef = this.dialogService.open(
            FormBackendAdminUserComponent,
            {
                defaultWrapper: false,
                classes: 'mon-dialog-size-sm',
                cb: () => {
                    this.getPage();
                },
            },
        );
        dialogRef.componentInstance.user = cloneDeep(user);
        dialogRef.componentInstance.reseller = this.reseller;
    }

    onConfirmDelete (user: UserInterface): void {
        this.resellersRepo.destroyUser(this.reseller.id, user.id, undefined, undefined).then(() => {
            this.toastService.success(this.translateService.getString('User deleted'));
            this.getPage();
        });
    }

    deleteUsers (): void {
        const chain: Promise<void>[] = [];
        const headers = new HttpHeaders({ noGlobal: 'true' });

        this.selectedUsers.forEach((user) => {
            chain.push(this.resellersRepo.destroyUser(this.reseller.id, user.id, {}, headers));
        });

        Promise.all(chain).finally(() => {
            this.promptService.alert(this.translateService.getString('Selected user deleted from reseller'));
            setTimeout(() => {
                this.selectedUsers.length = 0;
                this.page = 1;
                this.getPage();
            }, 200);
        });
    }
}
