export const PLAN_TRAITS = {
    SCAN_FREQUENIES: [
        {
            value: 7,
            name: 'Weekly',
        },
        {
            value: 14,
            name: 'Every 2 week',
        },
        {
            value: 28,
            name: 'Every 4 week',
        },
        {
            value: 56,
            name: 'Every 8 week',
        },
        {
            value: 84,
            name: 'Every 12 week',
        },
        {
            value: -1,
            name: 'Never',
        },
    ],
};
