export const DEFAULTS = {
    API: {
        PAGE_SIZE: 10,
    },
};

export const BROWSER_SERVICE = {
    LEGACY: 'browser_service',
    PUPPETEER: 'puppeteer_service',
};
