import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemType, SideMenuComponent } from '../../side-menu.component';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';

type CreateMenuItemInput = {icon: string[], href?: string} & Omit<MenuItemType, 'icon' | 'href'>

@Component({
    selector: 'mon-side-menu-backend-admin',
    standalone: true,
    imports: [CommonModule, SideMenuComponent],
    templateUrl: './side-menu-backend-admin.component.html',
    styleUrls: ['./side-menu-backend-admin.component.scss'],
})
export class SideMenuBackendAdminComponent implements OnInit {
    showBackendAdmin: boolean;
    menu: MenuItemType[];

    constructor (private translateService: TranslateService, private monIconsService: MonIconsService) {}

    basePath = 'base.backend_admin';

    createBackendMenuItem (input: CreateMenuItemInput): MenuItemType {
        if (input.href === undefined) {
            input.href = 'index';
        }

        return {
            ...input,
            icon: this.monIconsService.getIcon(input.icon),
            base: `${this.basePath}.${input.base}`,
            href: input.href ? `${this.basePath}.${input.base}.${input.href}` : `${this.basePath}.${input.base}`,
            backend: !input.tools,
        };
    }

    ngOnInit (): void {
        this.showBackendAdmin = true;

        const preFormattingMenu: CreateMenuItemInput[] = [
            { title: this.translateService.getString('Partners'), icon: ['PARTNERS', 'ICON'], base: 'resellers' },
            { title: this.translateService.getString('Users'), icon: ['USERS', 'ICON'], base: 'users' },
            { title: this.translateService.getString('Domains'), icon: ['DOMAINS', 'ICON'], base: 'domains' },
            { title: this.translateService.getString('Customers'),icon: ['CUSTOMERS', 'ICON'], base: 'customers',href: 'all' },
            { title: this.translateService.getString('Plans'), icon: ['PLANS', 'ICON'], base: 'plans' },
            { title: this.translateService.getString('HEB Report'), icon: ['ACTIONS', 'DOWNLOAD'], base: 'heb_report' },
            { title: this.translateService.getString('Crawl queue'), icon: ['CRAWL_QUEUE', 'ICON'], base: 'crawl_queue' },
            { title: this.translateService.getString('Support heatmaps'), icon: ['SUPPORT', 'HEATMAPS', 'ICON'], base: 'support.heatmaps', href: '', tools: true },
            { title: this.translateService.getString('Request checker'), icon: ['SUPPORT', 'REQUEST_CHECKER', 'ICON'], base: 'support.request-checker', href: '', tools: true },
            { title: this.translateService.getString('Search'), icon: ['SEARCH', 'ICON'],base: 'support.search', href: '', tools: true },
            { title: this.translateService.getString('Worker queue'), icon: ['WORKER_QUEUE', 'ICON'], base: 'workerqueue' },
            { title: this.translateService.getString('Crawlers'), icon: ['CRAWLERS', 'ICON'], base: 'crawlers' },
        ];

        this.menu = preFormattingMenu.map((item)=>this.createBackendMenuItem(item));
    }
}
