import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessibilitySourceCodeExclude } from '@monsido/modules/models/api/interfaces/accessibility-source-code-exclude.interface';
import { CMS } from '@monsido/core/constants/cms.constant';
import { AccessibilityChecksRepo } from '@monsido/modules/endpoints/api/admin/accessibility/accessibility-checks-repo';
import { AccessibilityCheckType } from 'app/services/api/support/models/accessibility-check-model';
import { cloneDeep } from 'lodash';
import { DialogService, FormBuilderValidationModule, FormsBuilderModule, LayoutModule, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { AccessibilitySourceCodeExcludeRepo } from '@monsido/modules/endpoints/api/admin/accessibility/accessibility-source-code-exclude.repo';
import { FormsModule, NgForm } from '@angular/forms';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SourceCodeExcludeEntryComponent } from './source-code-exclude-entry/source-code-exclude-entry.component';
import { Subject, debounce, interval } from 'rxjs';

type MatchRulesType = {type: string, value: string, accessibility_check_ids: number[]};

@Component({
    selector: 'mon-source-code-exclude',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        FormsBuilderModule,
        FormBuilderValidationModule,
        FormsModule,
        MonIconsPipe,
        NgbPaginationModule,
        SourceCodeExcludeEntryComponent,
    ],
    templateUrl: './source-code-exclude.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceCodeExcludeComponent implements OnInit, AfterViewInit {

    @Input() sourceCodeExclude: AccessibilitySourceCodeExclude;

    cmses = CMS;
    pageSize = 10;
    page = 1;
    defaultAccessibilityChecksOptions: AccessibilityCheckType[] = [];
    data: AccessibilitySourceCodeExclude;
    matchRulesCopy: MatchRulesType[] = [];
    typeOptions: {name: string, value: string}[];
    saving = false;
    dataIsValid = false;

    validate$ = new Subject();
    accessibilityChecksLoading = false;

    constructor (
        private accessibilityChecksRepo: AccessibilityChecksRepo,
        private translateService: TranslateService,
        private accessibilitySourceCodeExcludeRepo: AccessibilitySourceCodeExcludeRepo,
        private dialogService: DialogService,
        private cdref: ChangeDetectorRef,
    ) {

    }

    ngOnInit (): void {
        this.data = cloneDeep(this.sourceCodeExclude || {} as AccessibilitySourceCodeExclude);
        if (!Array.isArray(this.data.match_rules)) {
            this.data.match_rules = [];
        }

        if (!this.data.default_for_cms) {
            this.data.default_for_cms = null;
        }

        if (isNaN(parseFloat(this.data.id))) {
            this.addMatchRule();
        }
        this.matchRulesCopy = cloneDeep(this.data.match_rules);
        this.setupMatchRuleTypeOptions();
        this.setupDefaultAccessbilityChecksOptions();

        this.validate$.pipe(
            debounce(() => interval(100)),
        ).subscribe(() => {
            this.validateData();
            this.cdref.detectChanges();
        });
    }

    ngAfterViewInit (): void {
        this.hackyDropdownsSetUp();
    }

    async setupDefaultAccessbilityChecksOptions (): Promise<void> {
        this.accessibilityChecksLoading = true;
        try {
            const data = await this.accessibilityChecksRepo.getAll({ page_size: 0 }) as AccessibilityCheckType[];
            const div = document.createElement('div');
            this.defaultAccessibilityChecksOptions = data.map(entry => {
                div.innerHTML = entry.name;
                entry.name = div.innerText;
                return entry;
            });
        } finally {
            this.accessibilityChecksLoading = false;
            this.cdref.markForCheck();
        }
    }

    setupMatchRuleTypeOptions (): void {
        this.typeOptions = [
            {
                name: this.translateService.getString('Exact'),
                value: 'exact',
            },
            {
                name: this.translateService.getString('Regex'),
                value: 'regex',
            },
            {
                name: this.translateService.getString('Contains'),
                value: 'contains',
            },
        ];
    }

    submit (form: NgForm): void {
        if (form.valid && this.dataIsValid) {
            let promise;
            this.data.default_for_cms = this.data.default_for_cms || null;
            if (!Array.isArray(this.data.match_rules) || this.data.match_rules.length < 1) {
                this.data.match_rules = this.matchRulesCopy;
            }
            this.saving = true;
            if (isNaN(parseFloat(this.data.id))) {
                promise = this.accessibilitySourceCodeExcludeRepo.create(this.data);
            } else {
                promise = this.accessibilitySourceCodeExcludeRepo.update(this.data);
            }

            promise.then(() => {
                this.close();
            }, (): void => {}).finally(() => {
                this.saving = false;
            });
        }

    }

    addMatchRule (): void {
        this.data.match_rules.unshift({ type: 'exact', value: '', accessibility_check_ids: [] });
        this.page = 1;
    }

    close (): void {
        this.dialogService.closeAll();
    }

    onRuleRemove (index: number): void {
        const indexInArrayindex = index + (this.page - 1) * this.pageSize;
        this.data.match_rules.splice(indexInArrayindex, 1);
        this.validate$.next(null);
    }

    private validateData (): void {
        for (const entry of this.data.match_rules) {
            if (!entry.type || !entry.value) {
                this.dataIsValid = false;
                return;
            }
        }
        this.dataIsValid = true;
    }

    // We need to set the values of mon-form-field-select-ac
    // like that, otherwise they are turned to undefined
    // we do not know why
    private hackyDropdownsSetUp (): void {
        const { default_for_cms } = this.data;

        setTimeout(() => {
            this.data.default_for_cms = default_for_cms;
            this.cdref.detectChanges();
        }, 300);
    }

}
