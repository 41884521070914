import { Component, OnInit } from '@angular/core';
import { ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { CookieInterface } from '@monsido/modules/models/support/interfaces/cookie.interface';
import { CookieService } from '@monsido/pages/backend-admin/cookies/repo/cookie.service';
import { ParamService } from '@monsido/core/param/param.service';
import { UrlService } from '@uirouter/core';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormImportCookieComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/import/import-cookies.component';
import { DialogService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ImportCookiesService } from '@monsido/pages/backend-admin/cookies/repo/forms/import/import-cookies.service';
import { FormExportCookieComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/export/export-form.component';

@Component({
    selector: 'mon-backend-admin-cookie-repo',
    templateUrl: 'cookie-repo.html',
})
export class CookieRepoComponent extends BaseApiComponent implements OnInit {
    cookies: CookieInterface[] = [];
    constructor (
        private importCookiesService: ImportCookiesService,
        private toastService: ToastService,
        private location: UrlService,
        private paramService: ParamService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private cookieService: CookieService,
    ) {
        super(location);
    }

    ngOnInit (): void {
        this.loadLocations();
    }

    createCookie (e: MouseEvent): void {
        e.preventDefault();
        this.cookieService.createCookie(() => {
            this.getPage();
        });
    }

    importCookies (e: MouseEvent): void {
        e.preventDefault();
        this.dialogService.open(FormImportCookieComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                this.importCookiesService.resetSteps();
                this.getPage();
            },
        });
    }

    exportCookies (e: MouseEvent): void {
        e.preventDefault();
        this.dialogService.open(FormExportCookieComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: () => {},
        });
    }

    editCookie (e: MouseEvent, cookie: CookieInterface): void {
        e.preventDefault();
        this.cookieService.editCookie(cookie, () => {
            this.getPage();
        });
    }

    onDeleteCookie (cookie: CookieInterface, result: number): void {
        if (Number(cookie.id) === Number(result)) {
            this.cookieService.deleteCookie(cookie.id).then(() => {
                this.getPage();
            });
        } else {
            this.toastService.warning(this.translateService.getString('Wrong Answer'));
        }
    }

    getPage (): void {
        const params: Record<string, string | number> = {
            page: this.page,
            page_size: this.pageSize,
        };

        if (this.search) {
            params.search = this.search;
        }

        this.paramService.setParams({
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        });
        this.loading = true;
        this.cookieService
            .getAll(params)
            .then(
                (data: CookieInterface[]) => {
                    this.cookies = data;
                },
                (res) => {
                    this.loading = res.status === -1 && res.xhrStatus === 'abort';
                },
            )
            .finally(() => (this.loading = false));
    }
}
