import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'mon-password-reset',
    templateUrl: './password-reset.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule, FormsModule],
})
export class PasswordResetComponent {
    email = '';
    sendPasswordResetRequest (): void {}
}
