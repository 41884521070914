import { NgModule } from '@angular/core';
import { JobService } from '@monsido/modules/job/job.service';
import { TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendJobRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-job-repo';

@NgModule({
    imports: [TranslateModule],
    providers: [BackendJobRepo, JobService],
})
export class JobModule {}
