import { NgModule } from '@angular/core';
import { BaseLayoutModule } from '@layout/layout.module';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { ParamModule } from '@monsido/core/param/param.module';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ServicesModule } from '@monsido/services/services.module';
import {
    TableModule,
    TableSearchModule,
    TranslateModule,
    TabsModule,
    LayoutModule,
    IconsModule,
    DialogService,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataPrivacyChecksComponent } from './data-privacy-checks.component';
import { DataPrivacyChecksService } from './data-privacy-checks.service';
import { DATA_PRIVACY_CHECKS_STATES } from './data-privacy-checks.state';
import { SearchService } from './helper/search.service';
import { BackendDataPrivacyCheckRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-data-privacy-check.repo';

@NgModule({
    providers: [DialogService, BackendDataPrivacyCheckRepo, DataPrivacyChecksService, SearchService],
    imports: [
        ParamModule,
        BaseLayoutModule,
        UIRouterModule.forChild({ states: DATA_PRIVACY_CHECKS_STATES }),
        LayoutModule,
        CommonModule,
        ApiModule,
        TranslateModule,
        PipesModule,
        ServicesModule,
        TableModule,
        ConfirmModule,
        NgbModule,
        TabsModule,
        TableSearchModule,
        IconsModule,
    ],
    declarations: [DataPrivacyChecksComponent],
})
export class DataPrivacyChecksModule {}
