import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, LayoutModule, TabsModule, ToastService, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CustomerRepo } from 'app/services/api/admin/customer-repo';
import { API_CONSTANTS } from '@monsido/core/constants/api.constant';
import { StateParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { Customer } from '@monsido/modules/models/api/customer';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { FormCustomerComponent } from 'app/forms/customer/form-customer/form-customer.component';
import { BackendAdminCustomersModule } from '@monsido/pages/backend-admin/customers/customers.module';
import { UsersTabComponent, UsersTabGetParamsChangePayload } from './tabs/users-tab/users-tab.component';
import { User } from '@monsido/modules/models/api/user';
import { DomainsTabComponent, PageEventParamsType } from './tabs/domains-tab/domains-tab.component';
import { Domain } from '@monsido/modules/models/api/domain';
import { PlainHttpParams } from '@monsido/http/params';
import { FormCustomerUserComponent } from 'app/forms/form-customer-user/form-customer-user.component';
import { FormImportUsersComponent } from 'app/forms/import-users/import-users.component';
import { FormDomainComponent } from '@monsido/forms/domain/domain.component';
import { ParamService } from '@monsido/core/param/param.service';
import { PromptModule } from 'ng2/external/prompt/prompt.module';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'mon-customer-show',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        PromptModule,
        TabsModule,
        TranslateModule,
        BackendAdminCustomersModule,
        UsersTabComponent,
        DomainsTabComponent,
    ],
    templateUrl: './customer-show.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerShowComponent implements OnInit {
    readonly tabDefinitions = {
        customer: {
            icon: 'fas fa-building',
            value: 'customer',
            name: 'Customer and subscription information',
        },
        users: {
            icon: 'fas fa-users',
            value: 'users',
            name: 'Users',
        },
        domains: {
            icon: 'fas fa-globe',
            value: 'domains',
            name: 'Domains',
        },
    };

    activeTab: string;
    tabs: Record<string, string>[];

    customer: Customer;
    users: User[] = [];
    domains: Domain[];

    userPage = 1;
    domainPage = 1;
    userPageSize = API_CONSTANTS.PAGE_SIZE;
    domainPageSize = API_CONSTANTS.PAGE_SIZE;
    userSearch: string;
    domainSearch: string;
    userLoading = false;
    domainLoading = false;

    constructor (
        private translateService: TranslateService,
        private notificationService: ToastService,
        private loadingService: MonLoadingService,
        private customerRepo: CustomerRepo,
        private dialogService: DialogService,
        private uiRouter: UIRouterGlobals,
        private stateService: StateService,
        private paramService: ParamService,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit (): void {
        this.getCustomer();

        const { customer, users, domains } = this.tabDefinitions;

        this.tabs = [
            {
                faIcon: customer.icon,
                name: this.translateService.getString(customer.name),
                value: customer.value,
            },
            {
                faIcon: users.icon,
                name: this.translateService.getString(users.name),
                value: users.value,
            },
            {
                faIcon: domains.icon,
                name: this.translateService.getString(domains.name),
                value: domains.value,
            },
        ];

        this.setFromParams(this.uiRouter.params);
    }

    getCustomer (): void {
        this.customerRepo.get(this.uiRouter.params.customer_id)
            .then(customer => this.setCustomer(customer), (): void => {});
    }

    onDeleteCustomer (customer: Customer, id: string): void {
        if (customer.id != Number(id)) {
            this.notificationService.error(this.translateService.getString('Wrong ID'));
            return;
        }

        this.loadingService.show();
        this.dialogService.closeAll();

        this.customerRepo.destroy(customer.id).then(
            () => {
                this.loadingService.hide();
                this.notificationService.success(this.translateService.getString('Customer is deleted'));
                this.stateService.go('base.admin.customers.index');
            },
            (): void => {},
        );
    }

    editCustomer (): void {
        const dialogRef = this.dialogService.open(FormCustomerComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: (updatedCustomer: Customer) => {
                if (updatedCustomer) {
                    this.setCustomer(updatedCustomer);
                    this.getDomains();
                    this.getUsers();
                }
            },
        });

        dialogRef.componentInstance.customer = cloneDeep(this.customer);
    }

    onGetUsersParamsChange ({ page, pageSize, search }: UsersTabGetParamsChangePayload): void {
        this.userPage = page;
        this.userPageSize = pageSize;
        this.userSearch = search;
        this.getUsers();
    }

    getUsers (reloadAll: boolean = false): void {
        setTimeout(() => {
            const params: PlainHttpParams = {
                page: this.userPage,
                page_size: this.userPageSize,
            };

            if (this.userSearch) {
                params.search = this.userSearch;
            }
            this.paramService.setParams(params);
            this.userLoading = true;

            this.customerRepo.getAllUsers(this.customer.id, params).then(
                data => {
                    this.users = data;
                    this.userLoading = false;
                    this.cdRef.markForCheck();

                    if (reloadAll) {
                        this.getDomains();
                    }
                },
                res => {
                    this.userLoading = res.status === -1 && res.xhrStatus === 'abort';
                },
            );
        });
    }

    getDomains (params?: Partial<PageEventParamsType>): void {
        setTimeout(() => {
            const requestParams: PlainHttpParams = {
                page: this.domainPage,
                page_size: this.domainPageSize,
                ...params,
            };

            if (this.domainSearch) {
                requestParams.search = this.domainSearch;
            }

            this.paramService.setParams(params);
            this.domainLoading = true;

            this.customerRepo.getAllDomains(this.customer.id, requestParams).then(
                data => {
                    this.domains = data;
                    this.domainLoading = false;
                    this.cdRef.markForCheck();

                    if (params?.reloadAll) {
                        this.getUsers();
                    }
                },
                res => {
                    this.domainLoading = res.status === -1 && res.xhrStatus === 'abort';
                },
            );
        });
    }

    updateUsers (): void {
        this.userPage = 1;
        this.getUsers();
        this.getDomains();
    }

    createUser (): void {
        const dialogRef = this.dialogService.open(FormCustomerUserComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: () => this.updateUsers(),
        });

        dialogRef.componentInstance.customerId = this.customer.id;
    }

    importUsers (): void {
        const dialogRef = this.dialogService.open(FormImportUsersComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: () => this.updateUsers(),
        });

        dialogRef.componentInstance.customerId = this.customer.id;
    }

    createDomain (): void {
        const dialogRef = this.dialogService.open(FormDomainComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: () => {
                this.domainPage = 1;
                this.getUsers();
                this.getDomains();
            },
        });

        dialogRef.componentInstance.monDomain = new Domain();
        dialogRef.componentInstance.monCustomer = this.customer;
    }

    onTabChange (tab: string): void {
        this.activeTab = tab;
        this.paramService.setParams({ tab });
    }

    private setCustomer (customer: Customer): void {
        this.customer = customer;
        this.cdRef.markForCheck();
    }

    private setFromParams (params: StateParams): void {
        const tab = params?.tab || this.tabDefinitions.users.value;
        const search = params?.search || '';
        const pageSize = params?.page_size || API_CONSTANTS.PAGE_SIZE;
        const page = params?.page || 1;

        this.activeTab = tab;

        switch (tab) {
            case this.tabDefinitions.users.value:
                this.userSearch = search;
                this.userPageSize = pageSize;
                this.userPage = page;
                break;

            case this.tabDefinitions.domains.value:
                this.domainSearch = search;
                this.domainPageSize = pageSize;
                this.domainPage = page;
                break;
        }
    }
}
