import { NgModule } from '@angular/core';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { BACKEND_ADMIN_COOKIE_REPO } from '@monsido/pages/backend-admin/cookies/cookies.state';
import { BaseLayoutModule } from '@layout/layout.module';
import { CommonModule } from '@angular/common';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { CookieRepoComponent } from '@monsido/pages/backend-admin/cookies/repo/cookie-repo.component';
import { CookieService } from '@monsido/pages/backend-admin/cookies/repo/cookie.service';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ParamModule } from '@monsido/core/param/param.module';
import { UIRouterModule } from '@uirouter/angular';
import {
    TableModule,
    TableSearchModule,
    LayoutModule,
    TranslateModule,
    TabsModule,
    FormBuilderValidationModule,
    FormBuilderCheckboxModule,
    FormBuilderSelectModule,
    FormBuilderInputModule,
    TextareaModule,
    IconsModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormCookieComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/cookie/cookie-form.component';
import { PromptModule } from '../../../external/prompt/prompt.module';
import { FormImportCookieComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/import/import-cookies.component';
import { FormCookiesUploadComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/import/upload/upload.component';
import { FormCookieSetupComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/import/setup/setup.component';
import { FormExportCookieComponent } from '@monsido/pages/backend-admin/cookies/repo/forms/export/export-form.component';

@NgModule({
    imports: [
        ApiModule,
        BaseLayoutModule,
        CommonModule,
        ConfirmModule,
        FormBuilderCheckboxModule,
        FormBuilderInputModule,
        FormBuilderSelectModule,
        FormBuilderValidationModule,
        FormsModule,
        IconsModule,
        LayoutModule,
        NgbDropdownModule,
        NgbModule,
        ParamModule,
        PromptModule,
        TableModule,
        TableSearchModule,
        TabsModule,
        TextareaModule,
        TranslateModule,
        UIRouterModule.forChild({
            states: BACKEND_ADMIN_COOKIE_REPO,
        }),
        FormBuilderSelectModule,
    ],
    exports: [],
    declarations: [
        CookieRepoComponent,
        FormCookieComponent,
        FormExportCookieComponent,
        FormImportCookieComponent,
        FormCookiesUploadComponent,
        FormCookieSetupComponent,
    ],
    providers: [CookieService]
})
export class CookiesModule {}
