import {
    AdditionalInternalUrls,
    CmsExtra,
    CrawlHistory,
    CrawlStatus,
    DomainGroupDataType,
    DomainDataType,
    Features,
    LinkExcludes,
    PageAssistSettings,
    PathConstraints,
    Scan,
    Settings,
} from 'types/domain';
import moment from 'moment';
import { cloneDeep, merge } from 'lodash';
import { PAGE_FIX_DOMAIN_SETTINGS_MODULE } from '@monsido/core/constants/page-fix-domain-settings.constant';
import { DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { Customer } from '@monsido/modules/models/api/customer';
import { DomainBaseModel } from 'app/forms/import-users/steps/domains/domain-import/domain-base.model';

const offset = moment().utcOffset(); // Timezone in minutes
const weekDayOptions = ['any', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
let origTime: string;

export class Domain extends DomainBaseModel {
    active: boolean;
    id: number;
    customer_id: number;
    title: string;
    url: string;
    timezone?: string;
    cms: string;
    language: string;
    customer: Customer;
    last_scan: string;
    crawled_pages: number;
    crawl_status: CrawlStatus;
    crawl_history: CrawlHistory;
    domain_users?: DomainUsersEntity[];
    token: string;
    features: Features;
    scan: Scan;
    path_constraints?: PathConstraints[];
    link_excludes?: LinkExcludes[];
    excluded_ips?: string;
    domain_groups?: DomainGroupDataType[];
    running_groups: boolean;
    accessibility_source_code_excludes?: string[];
    cms_extra: CmsExtra;
    additional_internal_urls?: AdditionalInternalUrls[];
    source_code_excludes?: string[];
    created_at: string;
    updated_at: string;
    notes?: string;
    labels?: string[] | null;
    settings: Settings;
    page_assist_settings?: PageAssistSettings;

    constructor (domain?: DomainDataType) {
        super(domain);
        const defaultDomainModelValue: unknown = {
            active: false,
            domain_users: [],
            scan: {
                case_sensitive: true,
                connections_per_minute: 60,
                day: 'any',
                time: moment().set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
                    .format('HH:mm:ss'),
                use_lang_attribute: true,
            },
            features: {
                statistics: false,
                accessibility: null,
                readability_test: null,
            },
            additional_internal_urls: [],
            settings: {
                scripts: {
                    page_correct: {
                        enabled: false,
                        admin_only: false,
                        enabled_checks: [],
                    },
                },
            },
            link_excludes: [],
            path_constraints: [],
            cms_extra: this.getDefaultCmsExtra(),
        };

        Object.assign(this, merge(defaultDomainModelValue, cloneDeep(domain)));

        if (this.last_scan) {
            this.last_scan = moment(this.last_scan, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toISOString();
        }

        if (this.id > 0) {
            origTime = this.scan.time;
            this.scan.time = moment(this.scan.time, 'HH:mm:ss').add(offset, 'minutes')
                .format('HH:mm:ss');
            this.scan.day = this.getCurrentScanDay(this);
            this.updated_at = moment(this.updated_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
            this.created_at = moment(this.created_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
        }

        if (this.settings) {
            if (!this.settings.scripts.page_correct.enabled_checks || this.settings.scripts.page_correct.enabled_checks.length === 0) {
                this.settings.scripts.page_correct.enabled_checks = [
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.LINK,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.CUSTOM,
                ];
            }
        }
    }

    getScanDay (): string {
        if (this.scan.day !== 'any') {
            const weekDayNo = moment(this.scan.time, 'HH:mm:ss').day(this.scan.day)
                .utcOffset(0)
                .isoWeekday();
            return weekDayOptions[weekDayNo];
        }
        return this.scan.day;
    }

    getScanTime (): string {
        return moment(this.scan.time, 'HH:mm:ss').subtract(offset, 'minutes')
            .format('HH:mm:ss');
    }

    getDefaultCmsExtra (): CmsExtra {
        return {
            base_url: null,
            url: null,
            subdomain: null,
            language_id: null,
            areadynamicpage: null,
            newspage: null,
            employeepage: null,
            episerver_path: null,
            contentid_tag: null,
            language_tag: null,
            path_template: null,
            special_key: null,
            url_postfix: null,
            url_structure: null,
            version: null,
            prefix_path: null,
            cms_subdomain: null,
            default_language: null,
            site: null,
        };
    }

    clone (): DomainDataType {
        const clone = cloneDeep(this);
        clone.domain_users.forEach(function (user) {
            delete user.id;
            return user;
        });
        clone.link_excludes = clone.link_excludes.map(function (exclude) {
            delete exclude.id;
            return exclude;
        });
        clone.path_constraints = clone.path_constraints.map(function (constraint) {
            delete constraint.id;
            return constraint;
        });
        delete clone.crawl_history;
        delete clone.updated_at;
        delete clone.created_at;
        delete clone.crawl_status;
        delete clone.id;
        delete clone.url;
        delete clone.title;
        clone.domain_groups = [];
        return clone;
    }

    private getCurrentScanDay (model: Domain): string {
        if (model.scan.day !== 'any' && model.scan.day !== null) {
            const weekDayNo = moment(origTime, 'HH:mm:ss').day(model.scan.day)
                .add(offset, 'minutes')
                .isoWeekday();
            return weekDayOptions[weekDayNo];
        }

        return model.scan.day || 'any';
    }
}
