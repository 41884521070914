import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { Domain } from '@monsido/modules/models/api/domain';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { TranslateService, MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';

export interface GetDomainsParamsInterface {
    search?: string;
    page: number;
    pageSize: number;
}

@Component({
    selector: 'mon-domains-tab',
    templateUrl: './domains-tab.html',
})
export class DomainsTabComponent extends BaseApiComponent implements OnInit, OnChanges {
    @Input() customer: CustomerInterface;
    @Input() search: string;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() domainsArray: Domain[] = [];
    @Input() domains: CollectionInterface<Domain> = [];
    @Input() loading: boolean;

    @Output() getPageEvent: EventEmitter<GetDomainsParamsInterface> = new EventEmitter();
    @Output() createDomainEvent: EventEmitter<unknown> = new EventEmitter();
    @Output() importDomainEvent: EventEmitter<unknown> = new EventEmitter();

    selectedDomains: Domain[] = [];

    constructor (
        private monPromptService: MonPromptService,
        private backendDomainsRepo: BackendDomainsRepo,
        private translateService: TranslateService,
        $location: UrlService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        if (this.customer) {
            this.getPage();
        }
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.domains && changes.domains.currentValue) {
            const { currentValue } = changes.domains;
            this.domains = currentValue;
            this.domainsArray = Array.from(currentValue);
        }
    }

    getPage (): void {
        this.getPageEvent.emit({
            search: this.search,
            page: this.page,
            pageSize: this.pageSize,
        });
    }

    onSearchChanged (searchText: string): void {
        this.onSearch(searchText);
    }

    createDomain (): void {
        this.createDomainEvent.emit();
    }

    importDomains (): void {
        this.importDomainEvent.emit();
    }

    async deleteDomains (): Promise<void> {
        await Promise.all(this.selectedDomains.map((domain) => this.backendDomainsRepo.destroy(domain.customer_id, domain.id)));
        this.monPromptService.alert(this.translateService.getString('Selected domains have been deleted'), {
            size: 'md',
            callback: () => {
                this.selectedDomains.length = 0;
                this.page = 1;
                this.getPage();
            },
        });
    }
}
