import { Domain } from '@monsido/modules/models/api/domain';
import moment from 'moment/moment';
import {
    AdditionalInternalUrls,
    CmsExtra,
    CrawlHistory,
    CrawlStatus, DomainGroupDataType,
    Features, LinkExcludes, PageAssistSettings, PathConstraints,
    Scan, Settings,
} from 'types/domain';
import { Customer } from '@monsido/modules/models/api/customer';
import { DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { DomainBaseModel } from 'app/forms/import-users/steps/domains/domain-import/domain-base.model';

const offset = moment().utcOffset(); // Timezone in minutes
const weekDayOptions = ['any', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export class DomainForm extends DomainBaseModel {
    active: boolean;
    customer_id: number;
    url: string;
    timezone?: string;
    cms: string;
    language: string;
    customer: Customer;
    last_scan: string;
    crawled_pages: number;
    crawl_status: CrawlStatus;
    crawl_history: CrawlHistory;
    domain_users?: DomainUsersEntity[];
    token: string;
    features: Features;
    scan: Scan;
    path_constraints?: PathConstraints[];
    link_excludes?: LinkExcludes[];
    excluded_ips?: string;
    domain_groups?: DomainGroupDataType[];
    running_groups: boolean;
    accessibility_source_code_excludes?: string[];
    cms_extra: CmsExtra;
    additional_internal_urls?: AdditionalInternalUrls[];
    source_code_excludes?: string[];
    notes?: string;
    labels?: string[];
    settings: Settings;
    page_assist_settings?: PageAssistSettings;

    constructor (domain: Domain) {
        super(domain);
        this.active = domain.active;
        this.domainType = 'Domain';
        this.customer_id = domain.customer_id;
        this.url = domain.url;
        this.timezone = domain.timezone;
        this.cms = domain.cms;
        this.language = domain.language;
        this.customer = domain.customer;
        this.last_scan = domain.last_scan;
        this.crawled_pages = domain.crawled_pages;
        this.crawl_status = domain.crawl_status;
        this.crawl_history = domain.crawl_history;
        this.domain_users = domain.domain_users;
        this.token = domain.token;
        this.features = domain.features;
        this.scan = domain.scan;
        this.path_constraints = domain.path_constraints;
        this.link_excludes = domain.link_excludes;
        this.excluded_ips = domain.excluded_ips;
        this.domain_groups = domain.domain_groups;
        this.running_groups = domain.running_groups;
        this.accessibility_source_code_excludes = domain.accessibility_source_code_excludes;
        this.cms_extra = domain.cms_extra;
        this.additional_internal_urls = domain.additional_internal_urls;
        this.source_code_excludes = domain.source_code_excludes;
        this.notes = domain.notes;
        this.labels = domain.labels;
        this.settings = domain.settings;
        this.page_assist_settings = domain.page_assist_settings;
        this.handleScanTimeISOFormat();
    }

    // for compatibility with old Domain model
    handleScanTimeISOFormat (): void {
        const timeFromISOFormat = moment(new Date(this.scan.time));
        if (timeFromISOFormat.isValid()) {
            this.scan.time = timeFromISOFormat.format('HH:mm:ss');
        }
    }

    getScanDay (): string {
        if (this.scan.day !== 'any') {
            const weekDayNo = moment(this.scan.time, 'HH:mm:ss').day(this.scan.day)
                .utcOffset(0)
                .isoWeekday();
            return weekDayOptions[weekDayNo];
        }
        return this.scan.day;
    }

    getScanTime (): string {
        return moment(this.scan.time, 'HH:mm:ss').subtract(offset, 'minutes')
            .format('HH:mm:ss');
    }

    getDefaultCmsExtra (): CmsExtra {
        return {
            base_url: null,
            url: null,
            subdomain: null,
            language_id: null,
            areadynamicpage: null,
            newspage: null,
            employeepage: null,
            episerver_path: null,
            contentid_tag: null,
            language_tag: null,
            path_template: null,
            special_key: null,
            url_postfix: null,
            url_structure: null,
            version: null,
            prefix_path: null,
            cms_subdomain: null,
            default_language: null,
            site: null,
        };
    }
}
