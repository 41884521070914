import { NgModule } from '@angular/core';
import { LayoutModule, TranslateModule, TableModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BaseLayoutModule } from '@layout/layout.module';
import { BackendAdminDomainsTableComponent } from '@monsido/pages/backend-admin/domains/table.component';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { PromptModule } from '../../../external/prompt/prompt.module';
import { ConfirmModule } from '@monsido/confirm/confirm.module';

@NgModule({
    imports: [
        LayoutModule,
        BaseLayoutModule,
        TranslateModule,
        NgbTooltipModule,
        CommonModule,
        TableModule,
        UIRouterModule,
        PromptModule,
        ConfirmModule,
        IconsModule,
    ],
    declarations: [BackendAdminDomainsTableComponent],
    exports: [BackendAdminDomainsTableComponent],
    providers: [],
})
export class BackendAdminDomainsModule {}
