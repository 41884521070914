import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { tokenErrorMessages } from './token-issue-messages.constant';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { EventsTypeEnum } from '@monsido/services/mon-event/events.type';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    allreadyDialog: boolean;

    constructor (
        private monPromptService: MonPromptService,
        private translateService: TranslateService,
        private monEventService: MonEventService,
    ) { }

    intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const errorMessage: string = error && (error.error?.message || error.message) || '';

                switch (error.status) {
                    case 401:
                        if (tokenErrorMessages.includes(errorMessage)) {
                            this.monEventService.run(EventsTypeEnum.signOut);
                            return EMPTY;
                        }
                        break;
                    case 422:
                    case 400:
                        if (req.headers && !req.headers.has('noGlobal')) {
                            let messages: string = '';
                            // Not sure if there are any error response matches this format
                            if (error.error?.forEach) {
                                error.error.forEach((value: Array<string> | string, key: string) => {
                                    let params: string = '';

                                    if (Array.isArray(value)) {
                                        value.forEach((valueParams) => {
                                            if (params !== '') {
                                                params += ', ';
                                            }
                                            params += valueParams;
                                        });
                                    } else {
                                        params = value;
                                    }
                                    error.error[key] = params;
                                    key = key.replace(/\[|\]/g, '');
                                    messages += '<strong>' + key + '</strong>: ' + params + '<br />';
                                });
                            } else {
                                if (error.error?.errors) {
                                    Object.keys(error.error.errors).forEach((key) => {
                                        const value = error.error.errors[key];
                                        let params = '';

                                        if (Array.isArray(value)) {
                                            value.forEach((valueParams) => {
                                                if (params !== '') {
                                                    params += ', ';
                                                }
                                                params += valueParams;
                                            });
                                        } else {
                                            params = value;
                                        }
                                        error.error[key] = params;
                                        key = key.replace(/\[|\]/g, '');
                                        messages += '<strong>' + key + '</strong>: ' + params + '<br />';
                                    });
                                }
                            }
                            if (error.error === 'Plan is in use by customers') {
                                this.monPromptService.alert(this.translateService.getString('Plan cannot be deleted as it is currently being used by at least one customer.'));
                            } else {
                                this.monPromptService.alert(error.message + '\n\n' + messages);
                            }
                        }
                        break;
                    case 502:
                        if (!this.allreadyDialog) {
                            this.allreadyDialog = true;
                            this.monPromptService
                                .alert(
                                    'It seems like we are experience some issues with the page you are looking at. <br />' +
                                        'The Monsido team is notified and are working on the issue. <br /> ' +
                                        'Please contact the support if this issue keeps happening',
                                )
                                .then(
                                    () => throwError(() => error),
                                    () => throwError(() => error),
                                )
                                .finally(() => {
                                    this.allreadyDialog = false;
                                });
                        }

                        break;
                    case 0:
                    case -1:
                        if (!this.allreadyDialog && error.statusText !== 'abort') {
                            this.allreadyDialog = true;
                            let box = null;

                            if (window.navigator.onLine === false) {
                                this.allreadyDialog = true;
                                box = this.monPromptService
                                    .alert(
                                        'It seems like your computer is disconnected from the internet.' +
                                        '\nPlease refresh when your computer is online again',
                                    )
                                    .then(
                                        () => {},
                                        () => {},
                                    );
                            } else {
                                box = this.monPromptService.alert('An error has occurred - please try again');
                            }

                            box.finally(() => {
                                this.allreadyDialog = false;
                            });
                        }

                        break;
                    default:
                        if (req.headers && !req.headers.has('noglobal')) {
                            if (errorMessage) {
                                this.monPromptService.alert(errorMessage).then(
                                    () => throwError(() => error),
                                    () => throwError(() => error),
                                );
                            }
                        }
                        break;
                }
                return throwError(() => error);
            }),
        );
    }
}
