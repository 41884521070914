import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BackendAdminDomainsRepo } from 'app/services/api/backend_admin/backend-admin-domains-repo';
import { CustomerRepo } from 'app/services/api/admin/customer-repo';
import { ParamService } from '@monsido/core/param/param.service';
import { CrawlInterface } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';
import { Domain } from '@monsido/modules/models/api/domain';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { FormDomainComponent } from '@monsido/forms/domain/domain.component';
import { ChartDataset, ChartOptions, IconsModule, LayoutModule, MonChartsModule, TableModule, TooltipModel, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';
import { BehaviorSubject } from 'rxjs';
import { MonUserDatePipe } from 'app/filters/mon-user-date.pipe';
import { UIRouterModule } from '@uirouter/angular';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { orderBy } from 'lodash';

@Component({
    selector: 'mon-domain-show',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        IconsModule,
        TranslateModule,
        UIRouterModule,
        PipesModule,
        MonChartsModule,
        TableModule,
    ],
    providers: [
        DecimalPipe,
        MonUserDatePipe,
    ],
    templateUrl: './domain-show.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainShowComponent implements OnInit {

    @Input() isBackendAdmin = false;

    hideFeatures: string[] = ['nag_for_upgrade'];
    crawls: CrawlInterface[] = [];
    domain?: Domain;

    lineChartSeries: string[] = [];
    lineChartColors: string[] = [];
    lineChartLabels: string[] = [];
    lineChartData: unknown[] = [];
    lineChartOverride: Partial<ChartDataset<'line'>>[];
    lineChartOptions: ChartOptions;

    activePlanIcon = this.monIconsService.getIcon(['CUSTOMERS', 'PLAN_TRAITS', 'ACTIVE']);
    inactivePlanIcon = this.monIconsService.getIcon(['CUSTOMERS', 'PLAN_TRAITS', 'INACTIVE']);
    activeFeatureIcon = this.monIconsService.getIcon(['CUSTOMERS', 'FEATURES', 'ACTIVE']);
    inactiveFeatureIcon = this.monIconsService.getIcon(['CUSTOMERS', 'FEATURES', 'INACTIVE']);
    activeScanIcon = this.monIconsService.getIcon(['CUSTOMERS', 'SCAN', 'ACTIVE']);
    inactiveScanIcon = this.monIconsService.getIcon(['CUSTOMERS', 'SCAN', 'INACTIVE']);

    loading = false;

    private sortedCrawls: CrawlInterface[] = [];
    private tooltipModel: BehaviorSubject<TooltipModel<'line'>> = new BehaviorSubject(undefined);
    private repo: BackendAdminDomainsRepo | CustomerRepo;

    constructor (
        private backendAdminDomainsRepo: BackendAdminDomainsRepo,
        private customerRepo: CustomerRepo,
        private paramService: ParamService,
        private monEventService: MonEventService,
        private translateService: TranslateService,
        private monIconsService: MonIconsService,
        private decimalPipe: DecimalPipe,
        private monUserDatePipe: MonUserDatePipe,
        private cdref: ChangeDetectorRef,
    ) {

    }

    ngOnInit (): void {
        this.repo = this.isBackendAdmin ? this.backendAdminDomainsRepo : this.customerRepo;
        this.getDomain();
    }

    getDomain (): void {
        const { customer_id, domain_id } = this.paramService.getParams();

        this.loading = true;
        this.repo.getDomain(customer_id, domain_id).then((domain) => {
            this.domain = domain;
            return this.getCrawlHistory(domain_id, customer_id);
        }, () => {})
            .finally(() => {
                this.loading = false;
            });
    }

    private getCrawlHistory (domainId: number, customerId?: number): void {
        this.repo.getDomainCrawls({
            domainId,
            data: { page_size: 10 },
            customerId,
        }).then((crawls: CrawlInterface[]) => {
            this.crawls = orderBy(crawls), 'queued_at';
            this.sortedCrawls = (this.crawls as Array<CrawlInterface> & {toSorted: (...args: unknown[]) => CrawlInterface[]}).toSorted((a: CrawlInterface, b: CrawlInterface) => {
                const dateA = new Date(a.queued_at);
                const dateB = new Date(b.queued_at);
                if (dateA < dateB) {
                    return -1;
                }
                if (dateA > dateB) {
                    return 1;
                }
                return 0;
            });

            this.setupChart();
        }, () => {});
    }

    onEditDomain (): void {
        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: FormDomainComponent,
            data: {
                monDomain: this.domain,
                monIsBackendAdmin: true,
            },
            dialogOptions: {
                size: 'sm',
                cb: () => {
                    this.getDomain();
                },
            },
        });

    }

    private setupChart (): void {
        this.lineChartSeries = [this.translateService.getString('Crawled Pages')];
        this.lineChartData = [[]];
        this.lineChartColors = ['#249ff9'];
        this.lineChartLabels = [];
        this.lineChartOverride = [
            {
                type: 'line',
                label: this.translateService.getString('Crawled Pages'),
                tension: 0,
                pointRadius: 0,
                pointHitRadius: 30,
                fill: false,
                borderColor: '#249ff9',
                backgroundColor: '#249ff9',
                spanGaps: false,
                data: [],
            },
        ];

        this.lineChartOptions = {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        font: {
                            size: 11,
                        },
                        boxWidth: 10,
                    },
                },
                tooltip: {
                    enabled: false,
                    external: ({ tooltip }): void => {
                        tooltip;
                        this.tooltipModel.next(tooltip as TooltipModel<'line'>);
                    },
                    callbacks: {
                        label: (item): string => {
                            return this.decimalPipe.transform((item.dataset.data[item.dataIndex] as number), '0.0');
                        },
                    },
                },
            },
            scales: {
                y: {
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    beginAtZero: true,
                    suggestedMax: 4,
                    ticks: {
                        font: {
                            size: 11,
                        },
                        maxTicksLimit: 5,
                    },
                    grid: {
                        display: true,
                    },
                    border: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: this.translateService.getString('Crawled Pages'),
                        font: {
                            size: 11,
                        },
                    },
                    stacked: true,
                },
                x: {
                    beginAtZero: true,
                    suggestedMax: 4,
                    min: 0,
                    ticks: {
                        maxTicksLimit: 10,
                    },
                },
            },
        };

        if (this.domain.scan.scan_documents) {
            this.lineChartSeries.push(this.translateService.getString('Documents'));
            this.lineChartOptions.scales = {
                y1: {
                    type: 'linear',
                    position: 'right',
                    beginAtZero: true,
                    suggestedMax: 4,
                    min: 0,
                    ticks: {
                        maxTicksLimit: 5,
                        callback: (value) => this.decimalPipe.transform(value, '0.0'),
                    },
                    title: {
                        display: true,
                        text: this.translateService.getString('Documents'),
                        font: {
                            size: 11,
                        },
                    },
                },
            };


            this.lineChartOverride.push({
                type: 'line',
                label: this.translateService.getString('Documents'),
                tension: 0,
                pointRadius: 0,
                pointHitRadius: 30,
                fill: false,
                borderColor: '#CCCCCC',
                backgroundColor: '#CCCCCC',
                spanGaps: false,
                yAxisID: 'y1',
                data: [],
            });

            this.lineChartColors.push('#CCCCCC');
            this.lineChartData.push({ data: [] });
        }

        let crawl;
        for (let i = 0; i < this.sortedCrawls.length; i++) {
            crawl = this.sortedCrawls[i];
            this.lineChartLabels.push(this.monUserDatePipe.transform(crawl.queued_at as string));
            this.lineChartOverride[0].data.push(crawl.page_count);
            if (this.domain.scan.scan_documents) {
                this.lineChartOverride[1].data.push(crawl.documents_count);
            }
        }
        this.cdref.detectChanges();
    }
}
