<form name="form"
      #accessibilityCheckForm="ngForm"
      class="form-horizontal"
      id="accessibility-check-dialog"
      (submit)="submit(accessibilityCheckForm)">
    <div class="mon-dialog-header">
        <mon-page-header [monIcon]="['ACCESSIBILITY_CHECKS', 'ICON']"
                         header="{{'Edit Accessibility check' | translate }}"
                         subHeader="{{checkToUpdate.name}}"
                         *ngIf="checkToUpdate?.id">
        </mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer" *ngIf="checkToUpdate">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Help article location
                </h3>
            </div>

              <div class="card-body">
                <div class="form-group">
                    <label for="friendly_name" class="col-form-label col-sm-8" translate>
                        Article URL
                    </label>
                    <div class="col-sm-40">
                        <input type="text"
                               class="form-control"
                               id="article_url"
                               #articleUrl="ngModel"
                               monValidUrl
                               name="article_url"
                               [(ngModel)]="checkToUpdate.article_url"/>

                        <div class="block invalid-feedback"
                             *ngIf="articleUrl.touched && articleUrl.control.errors?.validUrl">
                            <span class="help-block" translate>
                                Insert a valid URL
                            </span>
                        </div>
                    </div>
                </div>
              </div>
        </div>

        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Check detail
                </h3>
            </div>
            <mon-spinner [loading]="!summernoteShow">
              <div class="card-body">
                <div class="form-group">
                    <label for="friendly_name" class="col-form-label col-sm-8" translate>
                        Friendly name
                    </label>
                    <div class="col-sm-40">
                        <input type="text"
                               class="form-control"
                               id="friendly_name"
                               name="friendly_name"
                               [(ngModel)]="checkToUpdate.friendly_name"/>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="col-form-label col-sm-8" translate>
                        Subheader
                    </label>
                    <div class="col-sm-40">
                        <input type="text"
                               class="form-control"
                               id="name"
                               name="name"
                               [(ngModel)]="checkToUpdate.name"/>
                    </div>
                </div>
                <div class="form-group">
                    <label for="check_id" class="col-form-label col-sm-8 pt-1" translate>
                        Check ID
                    </label>
                    <div class="col-sm-40 pt-1">
                        {{ checkToUpdate.check_id }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="locations" class="col-form-label col-sm-8 pt-1" translate>
                        Locations
                    </label>
                    <div class="col-sm-40 pt-1">
                        <span *ngFor="let location of checkToUpdate.locations">
                            {{location}}<br>
                        </span>
                    </div>
                </div>
                <div class="form-group" *ngIf="checkToUpdate.previous_check?.hasOwnProperty('check_id')">
                    <label for="previous_check" class="col-form-label col-sm-8 pt-1" translate>
                        Previous check version
                    </label>
                    <div class="col-sm-40 pt-1">
                        {{ checkToUpdate.previous_check != null ? checkToUpdate.previous_check.check_id : 'None' }}
                    </div>
                </div>
                <div class="form-group" *ngIf="checkToUpdate.following_check?.hasOwnProperty('check_id')">
                    <label for="following_check" class="col-form-label col-sm-8 pt-1" translate>
                        Newer check version
                    </label>
                    <div class="col-sm-40 pt-1">
                        {{ checkToUpdate.following_check != null ? checkToUpdate.following_check?.check_id : 'None' }}
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label col-sm-8" for="difficulty" translate>Difficulty</label>
                    <div class="col-sm-40">
                        <mon-form-field-select-ac
                            name="difficulty"
                            monPlaceholder="{{ 'Difficulty' | translate }}"
                            monLabel="{{ 'Difficulty' | translate }}"
                            [monSrOnlyLabel]="true"
                            [(ngModel)]="checkToUpdate.difficulty"
                            monReturnValue="value"
                            [monOptions]="levels"
                        ></mon-form-field-select-ac>
                    </div>
                </div>

               <div class="form-group">
                   <label class="col-form-label col-sm-8" for="tags" translate>Tags*</label>
                   <div class="col-sm-40">

                    <mon-form-field-select-ac
                        name="tags"
                        monPlaceholder="{{ 'Add tags' | translate }}"
                        monLabel="{{ 'Add tags' | translate }}"
                        [monSrOnlyLabel]="true"
                        [(ngModel)]="checkToUpdate.tags"
                        monReturnValue="value"
                        [monOptions]="tags"
                        monGroup="group"
                        [monMultiple]="true"
                    ></mon-form-field-select-ac>
                   </div>
               </div>

               <div class="form-group">
                   <label class="col-form-label col-sm-8" for="responsibilities" translate>Responsibilities</label>
                   <div class="col-sm-40">

                    <mon-form-field-select-ac
                        name="responsibilities"
                        monPlaceholder="{{ 'Add responsibilities' | translate }}"
                        monLabel="{{ 'Add responsibilities' | translate }}"
                        [monSrOnlyLabel]="true"
                        [(ngModel)]="checkToUpdate.responsibilities"
                        monReturnValue="value"
                        [monOptions]="responsibilities"
                        monGroup="group"
                        [monMultiple]="true"
                    ></mon-form-field-select-ac>
                   </div>
               </div>

                <div class="form-group">
                    <label class="col-sm-offset-8 col-sm-40" translate>Impact levels</label>
                    <div class="col-sm-offset-8 col-sm-40">
                        <div class="d-flex align-items-center flex-wrap">
                            <div *ngFor="let impact of impactLevels; index as i"
                                 class="cursor-pointer"
                                 [ngClass]="{'pr-2': i+1 < impactLevels.length}">
                                <input type="radio"
                                       id="impact-level{{i}}"
                                       class="pull-left mt-1"
                                       name="impact-level"
                                       [(ngModel)]="checkToUpdate.impact"
                                       [value]="impact.value">
                                <label for="impact-level{{i}}"
                                       class="pull-left pl-2 inner-t-smurf font-weight-400">
                                    {{impact.name}}
                                </label>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="short_description" translate>Short Description*</label>
                    <div class="col-sm-40">
                        <div name="short_description" [(ngModel)]="checkToUpdate.short_description" [ngxSummernote]="summernoteConfig"></div>
                    </div>
                </div>
                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="description" translate>Full Description*</label>
                    <div class="col-sm-40">
                        <div name="description" [(ngModel)]="checkToUpdate.description" required="true" [ngxSummernote]="summernoteConfig"></div>
                    </div>
                </div>
                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="fail_examples" translate>Fail Examples*</label>
                    <div class="col-sm-40">
                        <div name="fail_examples" [(ngModel)]="checkToUpdate.fail_examples" required="true" [ngxSummernote]="summernoteConfig"></div>
                    </div>
                </div>
                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="pass_examples" translate>Pass Examples*</label>
                    <div class="col-sm-40">
                        <div name="pass_examples" [(ngModel)]="checkToUpdate.pass_examples" required="true" [ngxSummernote]="summernoteConfig"></div>
                    </div>
                </div>
                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="how_to_repair" translate>How to Repair*</label>
                    <div class="col-sm-40">
                        <div name="how_to_repair" [(ngModel)]="checkToUpdate.how_to_repair" required="true" [ngxSummernote]="summernoteConfig"></div>
                    </div>
                </div>
                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="quick_guide" translate>Quick Guide*</label>
                    <div class="col-sm-40">
                        <div name="quick_guide" [(ngModel)]="checkToUpdate.quick_guide" required="true" [ngxSummernote]="summernoteConfig"></div>
                    </div>
                </div>
                <div class="form-group" *ngIf="summernoteShow">
                    <label class="col-form-label col-sm-8" for="html_element" translate>HTML Element*</label>
                    <div class="col-sm-40">
                        <mon-form-field-select-ac
                            name="html_element"
                            monPlaceholder="{{ 'Add HTML Element' | translate }}"
                            monLabel="{{ 'Add HTML Element' | translate }}"
                            [monSrOnlyLabel]="true"
                            [(ngModel)]="checkToUpdate.html_element"
                            monReturnValue="value"
                            [monOptions]="htmlElements"
                            [required]="true"
                        ></mon-form-field-select-ac>
                    </div>
                </div>

                <h2 *ngIf="summernoteShow" translate>Other Sections</h2>

                <ng-container *ngIf="summernoteShow">
                    <div *ngFor="let other of checkToUpdate.others; index as i">
                        <div class="form-group">
                            <label class="col-form-label col-sm-8" for="others_{{i}}_title" translate>Title</label>
                            <div class="col-sm-36 pr-sm-0">
                                <input type="text"
                                       class="form-control"
                                       id="others_{{i}}_title"
                                       name="others_{{i}}_title"
                                       [(ngModel)]="checkToUpdate.others[i].title" />
                            </div>
                            <div class="col-sm-4 pl-sm-0 text-right">
                                <button class="btn btn-secondary" type="button" (click)="removeOther(i)" ngbTooltip="{{'Remove section' | translate}}">
                                    <span class="far fa-trash-alt" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label col-sm-8" for="others_{{i}}_description" translate>Description</label>
                            <div class="col-sm-36 pr-sm-0">
                                <div [(ngModel)]="checkToUpdate.others[i].description"
                                     [ngxSummernote]="summernoteConfig"
                                     name="others_{{i}}_description"
                                     id="others_{{i}}_description"
                                     config="summernoteConfig">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row mb-3" *ngIf="summernoteShow">
                    <div class="col-sm-offset-8">
                        <button class="btn btn-secondary" type="button" (click)="addOther()" ngbTooltip="{{'Add Section' | translate}}">
                            <span class="fas fa-plus" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
              </div>
            </mon-spinner>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-secondary" [disabled]="onSave || !accessibilityCheckForm.valid" data-test="save-button">
            <span *ngIf="!onSave">{{ 'Save' | translate}}</span>
            <span class="fa-spin fa-fw" [ngClass]="['SPINNER', 'ICON'] | monIcons" *ngIf="onSave" aria-hidden="true"></span>
        </button>
    </div>
</form>
