import { Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomainModel } from 'app/forms/import-users/steps/domains/domain-import/domain-model';
import { DomainGroupMembersEntity, UserInterface, DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { MonSelectAllCheckboxModule, TranslateModule, LayoutModule, TableModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { DomainGroupDataType } from 'types/domain';
import { cloneDeep } from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { Domain } from '@monsido/modules/models/api/domain';
import { CustomerRepo } from '../../../../services/api/admin/customer-repo';

@Component({
    selector: 'mon-form-customer-user-domains',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        MonIconsPipe,
        MonSelectAllCheckboxModule,
        TableModule,
    ],
    templateUrl: './form-customer-user-domains.component.html',
})
export class FormCustomerUserDomainsComponent implements OnInit, OnChanges {
    @Input() user: UserInterface;
    @Input() customerId: number;
    loading: boolean = false;
    domains: Array<DomainModel>;
    collection: Domain[];
    domainsOutput: Array<DomainModel> = [];
    page: number = 1;
    pageSize: number = 10;

    constructor (
        private cdRef: ChangeDetectorRef,
        private customerRepo: CustomerRepo,
    ) {}

    ngOnInit (): void {
        this.getDomains();
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes['user']) {
            if (this.user) {
                if (!this.user.domain_users) {
                    this.user.domain_users = [];
                }
                if (!this.user.domain_group_members) {
                    this.user.domain_group_members = [];
                }
            }
        }
    }

    onPageChange (page: number): void {
        this.page = page;
        this.getDomains();
    }

    onPageSizeChange (size: number): void {
        this.pageSize = size;
        this.page = 1;
        this.getDomains();
    }

    private async getDomains (): Promise<void> {
        this.loading = true;
        try {
            const domains = await this.customerRepo
                .getAllDomains(
                    this.customerId,
                    { page_size: this.pageSize, page: this.page },
                    new HttpHeaders({}),
                );
            this.collection = domains;
            this.domains = domains.map((domain: Domain) => new DomainModel(domain));
            this.domainsOutput = this.setup(this.domains);
        } finally {
            this.loading = false;
            this.cdRef.markForCheck();
        }
    }

    toggleDomainVisible (domain: DomainModel): void {
        this.toggleVisible(this.getDomainDataFromUser(domain));
    }

    toggleGroupVisible (group: DomainGroupDataType): void {
        this.toggleVisible(this.getGroupDataFromUser(group));
    }

    toggleDomainSendReport (domain: DomainModel): void {
        this.toggleSendReport(this.getDomainDataFromUser(domain));
    }

    toggleGroupSendReport (group: DomainGroupDataType): void {
        this.toggleSendReport(this.getGroupDataFromUser(group));
    }

    private toggleSendReport (entry: {send_report: boolean}): void {
        if (entry) {
            entry.send_report = !entry.send_report;
        }
    }

    private toggleVisible (entry: {visible: boolean}): void {
        if (entry) {
            entry.visible = !entry.visible;
        }
    }

    private setup (domains: DomainModel[]): DomainModel[] {
        const result = cloneDeep(domains);
        result.forEach(domain => {
            this.processDomainData(domain);
            if (domain.domain_groups) {
                domain.domain_groups.forEach(group => this.processGroupData(group));
            }
        });
        return result;
    }

    private processDomainData (domain: DomainModel): void {
        const groupData = this.getDomainDataFromUser(domain);

        if (groupData) {
            domain.visible = groupData.visible;
            domain.sendReport = groupData.send_report;
        } else {
            this.addNewDomainUser(domain.id);
        }
    }

    private processGroupData (group: DomainGroupDataType): void {
        const groupData = this.getGroupDataFromUser(group);

        if (groupData) {
            group.visible = groupData.visible;
            group.sendReport = groupData.send_report;
        } else {
            this.addNewDomainGroupMember(group.id);
        }
    }

    private addNewDomainUser (id: number): void {
        this.user['domain_users'].push({
            domain_id: id,
            visible: false,
            send_report: false,
        } as DomainUsersEntity);
    }

    private addNewDomainGroupMember (id: number): void {
        this.user['domain_group_members'].push({
            domain_group_id: id,
            visible: false,
            send_report: false,
        } as DomainGroupMembersEntity);
    }

    private getDomainDataFromUser (entry: DomainModel ): DomainUsersEntity | undefined {
        return this.user.domain_users.find(e => e.domain_id === entry.id);
    }

    private getGroupDataFromUser (entry: DomainGroupDataType): DomainGroupMembersEntity | undefined {
        return this.user.domain_group_members.find(e => e.domain_group_id === entry.id);
    }

}
