<form name="form" class="form-horizontal" #customerForm="ngForm" (ngSubmit)="onSubmitClick()">
    <div *ngIf="model" class="mon-dialog-header">
        <mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'Edit customer' | translate }}" *ngIf="model.id"></mon-page-header>
        <mon-page-header [monIcon]="['CUSTOMERS', 'ICON']" header="{{'New customer' | translate }}" *ngIf="!model.id"></mon-page-header>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Customer detail
                </h3>
            </div>
            <div class="card-body">
                <mon-form-backend-admin-customer-detail
                    [verticalsOptions]="verticalsOptions$ | async"
                    [customer]="model">
                </mon-form-backend-admin-customer-detail>
            </div>
        </div>
        <div class="card monsido-panel mt-3 mx-3">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Subscription
                </h3>
            </div>
            <div class="card-body">
                <mon-form-backend-admin-customer-plan
                    (outputPlan)="setPlan($event)"
                    [customer]="model"
                    [reseller]="reseller">
                </mon-form-backend-admin-customer-plan>
            </div>
        </div>
        <div class="card monsido-panel mt-3 mx-3 mb-8">
            <div class="card-header">
                <h3 class="font-weight-300" translate>
                    Plan overrides
                </h3>
            </div>
            <div>
                <mon-form-backend-admin-customer-override
                        [plan]="plan"
                        [customer]="model"
                        (selectionChange)="onSelectionChange()">
                </mon-form-backend-admin-customer-override>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer text-right">
        <button class="btn btn-secondary" [disabled]="onSave || customerForm.invalid" data-test="save-button">
            <span *ngIf="!onSave" translate>Save</span>
            <mon-icon *ngIf="onSave" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
        </button>
    </div>
</form>
