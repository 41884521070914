import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormBuilderSelectModule, FormsBuilderModule, TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { DomainGroupMembersEntity, DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';

export type UserImportSelectedModel = {
    emailIndex?: number,
    passwordIndex?: number,
    isAdminIndex?: number,
    firstNameIndex?: number,
    lastNameIndex?: number,
    domainGroups?: DomainGroupMembersEntity[],
    domains?: DomainUsersEntity[],
}

export type UserImportCSVData = {
    data: string[][],
}

type UserImportCSVDataOption = {
    name: string,
    value: number,
}

@Component({
    selector: 'mon-form-user-import-setup',
    templateUrl: './setup.component.html',
    standalone: true,
    imports: [
        FormsModule,
        FormsBuilderModule,
        TranslateModule,
        FormBuilderSelectModule,
    ],
})
export class FormUserImportSetupComponent implements OnInit {
    @Input() data: UserImportCSVData;
    @Input() selected: UserImportSelectedModel = {};

    options: UserImportCSVDataOption[] = [];

    ngOnInit (): void {
        const datum = this.data?.data[0];

        if (!Array.isArray(datum)) {
            return;
        }

        for (let i = 0; i < datum.length; i++) {
            this.options.push({ name: datum[i], value: i });
        }

        for (const option of this.options) {
            const index = option.value;

            switch (option.name.toLowerCase()) {
                case 'email':
                    this.selected.emailIndex = index;
                    break;

                case 'password':
                    this.selected.passwordIndex = index;
                    break;

                case 'is admin':
                    this.selected.isAdminIndex = index;
                    break;

                case 'first name':
                    this.selected.firstNameIndex = index;
                    break;

                case 'last name':
                    this.selected.lastNameIndex = index;
                    break;
            }
        }
    }
}
