import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LANGUAGE_CONSTANT } from '@monsido/core/constants/languages.constant';
import { NgForm } from '@angular/forms';
import { ToastService, ActiveDialog, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ResellerAdminUsersRepo } from '@monsido/modules/endpoints/api/admin/reseller-admin-users.repo';
import { User } from '@monsido/modules/models/api/user';
import { UserForm } from './user-form';
import { BaseForm } from '@monsido/forms/base-form';

@Component({
    selector: 'mon-form-reseller-admin-user',
    templateUrl: 'user.component.html',
})
export class FormResellerAdminUserComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() user: User = new User();
    @Input() customerId: number;
    saving: boolean = false;
    formUser: UserForm;
    @ViewChild('userForm', { static: false }) userForm: NgForm;
    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private resellerAdminUsersRepo: ResellerAdminUsersRepo,
    ) {
        super();
    }

    ngOnInit (): void {
        this.formUser = new UserForm(this.user);
    }

    ngAfterViewInit (): void {
        if (this.userForm) {
            setTimeout(() => this.resetFormState(this.userForm));
            this.activeDialog.setForm(this.userForm);
        }
    }

    submit (): void {
        this.saving = true;
        if (this.formUser.id) {
            if (!this.formUser.password || this.formUser.password === this.formUser.password_confirmation) {
                this.resellerAdminUsersRepo
                    .update(this.formUser.id, this.formUser)
                    .then(
                        () => {
                            this.resetFormState(this.userForm);
                            this.close();
                            this.toastService.success(this.translateService.getString('User updated'));
                        },
                        () => {},
                    )
                    .finally(() => {
                        this.saving = false;
                    });
            } else {
                this.toastService.warning(this.translateService.getString('Passwords do not match'));
                this.saving = false;
            }
        } else {
            this.formUser.locale = LANGUAGE_CONSTANT.en.code;
            this.resellerAdminUsersRepo
                .create(this.formUser)
                .then(
                    () => {
                        this.resetFormState(this.userForm);
                        this.close();
                        this.toastService.success(this.translateService.getString('User created'));
                    },
                    () => {},
                )
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    close (): void {
        this.activeDialog.close(this.formUser);
    }
}
