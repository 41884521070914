import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModule, LayoutModule, TableModule, TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { AccessibilitySourceCodeExcludeRepo } from '@monsido/modules/endpoints/api/admin/accessibility/accessibility-source-code-exclude.repo';
import { SourceCodeExcludeComponent } from 'app/forms/source-code-exclude/source-code-exclude.component';
import { ParamService } from '@monsido/core/param/param.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { AccessibilitySourceCodeExclude } from '@monsido/modules/models/api/interfaces/accessibility-source-code-exclude.interface';
import { DEFAULTS } from '@monsido/core/constants/defaults.constant';

@Component({
    selector: 'mon-source-code-excludes',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TableModule,
        TranslateModule,
        ConfirmModule,
    ],
    templateUrl: './source-code-excludes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceCodeExcludesComponent extends BaseApiComponent implements OnInit {

    sourceCodeExcludes: AccessibilitySourceCodeExclude[] = [];


    constructor (
        $location: UrlService,
        private accessibilitySourceCodeExcludeRepo: AccessibilitySourceCodeExcludeRepo,
        private paramService: ParamService,
        private monEventService: MonEventService,
        private cdref: ChangeDetectorRef,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.loadLocations();
        this.getPage();
    }

    getPage (): void {
        this.paramService.setParams({
            page_size: this.pageSize,
            page: this.page,
        });
        this.getSourceCodeExcludes();
    }

    getSourceCodeExcludes (): void {
        const params = {
            page: this.page,
            page_size: this.pageSize,
        };

        this.loading = true;
        this.accessibilitySourceCodeExcludeRepo.getAll(params).then((excludes) => {
            this.sourceCodeExcludes = excludes;
        }, (res) => {
            this.loading = res.status === -1 && res.xhrStatus === 'abort';
        })
            .finally(() => {
                this.loading = false;
                this.cdref.detectChanges();
            });
    }

    openDialog (sourceCodeExclude?: AccessibilitySourceCodeExclude): void {
        sourceCodeExclude = sourceCodeExclude || {
            match_rules: {
                type: '', value: '', accessibility_check_ids: [],
            },
        } as unknown as AccessibilitySourceCodeExclude;

        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: SourceCodeExcludeComponent,
            data: {
                sourceCodeExclude,
            },
            dialogOptions: {
                size: 'lg',
                cb: () => this.getPage(),
            },
        });
    }

    deleteSourceCodeExclude (sourceCodeExclude: AccessibilitySourceCodeExclude): void {
        this.accessibilitySourceCodeExcludeRepo.destroy(sourceCodeExclude.id).then(() => {
            this.getPage();
        }, () => {});
    }

}
