import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Customer } from '@monsido/modules/models/api/customer';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';

@Component({
    selector: 'mon-table-customers',
    standalone: true,
    imports: [CommonModule, UIRouterModule, TranslateModule],
    templateUrl: './table-customers.component.html',
})
export class TableCustomersComponent {
    @Input() customers: Customer[];

    stopPropagation (event:Event): void {
        event.stopPropagation();
    }
}
