import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, FilterButtonModule, LayoutModule, TableFiltersModule, TableModule, TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { TableFiltersInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/tableFilters.interface';
import {
    BackendAccessibilityChecksRepo,
    GetAllAccessibilityChecksParams,
} from '../../../services/api/support/admin/backend-accessibility-checks.repo';
import {
    AccessibilityChecksHelperService,
} from '../../../blocks/helpers/accessibility/accessibility-checks-helper.service';
import {
    OutputAccessibilityCheckMakerService, OutputAccessibilityCheckType,
} from '../../../services/api/support/models/output-accessibility-check-maker-service/output-accessibility-check-maker.service';
import { cloneDeep } from 'lodash';
import {
    AccessibilityCheckComponent,
} from '../../../forms/backend_admin/accessibility-check/accessibility-check.component';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';

@Component({
    selector: 'mon-backend-admin-accessibility-checks',
    templateUrl: 'backend-admin-accessibility-checks.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, LayoutModule, TableModule, TranslateModule, FilterButtonModule, TableFiltersModule],
})

export class BackendAdminAccessibilityChecksComponent extends BaseApiComponent implements OnInit {
    filters: TableFiltersInterface = {
        tags: [],
        difficulty: [],
        impact: [],
        responsibilities: [],
    };
    lastActiveFilters: TableFiltersInterface = {};

    checks: CollectionInterface<OutputAccessibilityCheckType> = [];
    isFiltersOpen = false;

    constructor (
        urlService: UrlService,
        private cdRef: ChangeDetectorRef,
        private backendAccessibilityChecksRepo: BackendAccessibilityChecksRepo,
        private dialogService: DialogService,
        private accessibilityChecksHelperService: AccessibilityChecksHelperService,
        private outputAccessibilityCheckMakerService: OutputAccessibilityCheckMakerService,
    ) {
        super(urlService);
    }

    async ngOnInit (): Promise<void> {
        this.filters = {
            tags: this.accessibilityChecksHelperService.getTags(),
            difficulty: this.accessibilityChecksHelperService.getLevels(),
            impact: this.accessibilityChecksHelperService.getImpactLevels(),
            responsibilities: this.accessibilityChecksHelperService.getResponsibilities(),
        };
        this.lastActiveFilters = cloneDeep(this.filters);
        await this.getPage();
    }

    async updateFilters (changes: TableFiltersInterface): Promise<void> {
        const activatedDifficultyFilters = changes.difficulty.filter(filter => filter.active);
        const previouslyActivatedDifficultyFilterIndex = this.lastActiveFilters.difficulty.findIndex(filter => filter.active);
        if (activatedDifficultyFilters.length > 1 && previouslyActivatedDifficultyFilterIndex > -1) {
            this.filters.difficulty[previouslyActivatedDifficultyFilterIndex].active = false;
        }

        const activatedImpactFilters = changes.impact.filter(filter => filter.active);
        const previouslyActivatedImpactFilterIndex = this.lastActiveFilters.impact.findIndex(filter => filter.active);
        if (activatedImpactFilters.length > 1 && previouslyActivatedImpactFilterIndex > -1) {
            this.filters.impact[previouslyActivatedImpactFilterIndex].active = false;
        }

        this.filters.tags = changes.tags;
        this.filters.responsibilities = changes.responsibilities;
        this.lastActiveFilters = cloneDeep(this.filters);
        await this.getPage();
    }

    filterOpenChanges (status: boolean): void {
        this.isFiltersOpen = status;
        this.cdRef.markForCheck();
    }

    openDialog (check: OutputAccessibilityCheckType): void {
        const dialogRef = this.dialogService.open(AccessibilityCheckComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                this.getPage();
            },
        });
        dialogRef.componentInstance.check = check;
    }

    async getPage (): Promise<void> {
        const params: GetAllAccessibilityChecksParams = {
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        };

        const activatedTagsFilters = this.filters.tags
            .filter(
                criteria => criteria.active,
            )
            .map(tag => tag.value);
        if (activatedTagsFilters.length) {
            params['tags[]'] = activatedTagsFilters as string[];
        }

        const activatedResponsibilitiesFilters = this.filters.responsibilities
            .filter(criteria => criteria.active)
            .map(responsibility => responsibility.value);
        if (activatedResponsibilitiesFilters.length) {
            params['responsibilities[]'] = activatedResponsibilitiesFilters as string[];
        }

        const activatedDifficultyFilter = this.filters.difficulty
            .find(criteria => criteria.active);
        if (activatedDifficultyFilter) {
            params.difficulty = activatedDifficultyFilter.value as string;
        }

        const activatedImpactFilter = this.filters.impact
            .find(criteria => criteria.active);
        if (activatedImpactFilter) {
            params.impact = activatedImpactFilter.value as string;
        }

        this.loading = true;

        try {
            const checks = await this.backendAccessibilityChecksRepo.getAll(params);
            for (let i = 0; i < checks.length; i++) {
                checks[i] = this.outputAccessibilityCheckMakerService.decorate(checks[i]);
            }
            this.checks = checks as CollectionInterface<OutputAccessibilityCheckType>;
            this.loading = false;
        } finally {
            this.loading = false;
        }

        this.cdRef.markForCheck();
    }
}
