import { Injectable } from '@angular/core';
import { ParseResult } from 'ngx-papaparse';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepType } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.service';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';

@Injectable({
    providedIn: 'root',
})
export class ImportCsvService {
    private step: BehaviorSubject<StepType> = new BehaviorSubject<StepType>(1);

    constructor (private translateService: TranslateService) {}

    setStep (step: StepType): void {
        this.step.next(step);
    }

    getStepObs (): Observable<StepType> {
        return this.step.asObservable();
    }

    getCurrentStep (): StepType {
        return this.step.getValue();
    }

    resetSteps (): void {
        this.step.next(1);
    }

    setupOptions (data: ParseResult): Record<string, string>[] {
        const options = [];
        for (let i = 0; i < data.data[0].length; i++) {
            options.push({ name: data.data[0][i], value: i.toString() });
        }

        return options;
    }

    setupSelected (options: Record<string, string>[], prop: string): { [name: string]: string } {
        const selected: { [name: string]: string } = {};
        for (let i = 0; i < options.length; i++) {
            switch (options[i].name.toLowerCase()) {
                case 'constraint':
                    selected[prop] = options[i].value;
                    break;
                case 'link_exclude':
                    selected[prop] = options[i].value;
                    break;
            }
        }

        return selected;
    }

    getConfirmText (data: ParseResult): string {
        return this.translateService.getString(`Import [[data]] items ?`, { data: data.data.length - 1 });
    }
}
