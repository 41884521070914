import { Injectable } from '@angular/core';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { CookieType } from '@monsido/modules/models/support/interfaces/cookie.interface';

@Injectable({
    providedIn: 'root',
})
export class CookieCategoriesLocalizeService {

    constructor (private translateService: TranslateService) {}

    CookieCategoriesLocalize (): Record<string, string> {
        const categories: Record<string, string> = {};
        categories[CookieType.ANALYTICS] = this.translateService.getString('Statistics');
        categories[CookieType.BASIC] = this.translateService.getString('Basic');
        categories[CookieType.ESSENTIAL] = this.translateService.getString('Essential');
        categories[CookieType.MARKETING] = this.translateService.getString('Marketing');
        categories[CookieType.PERSONALISATION] = this.translateService.getString('Preferences');

        return categories;
    }
}
