import { NgModule } from '@angular/core';
import { BaseLayoutModule } from '@layout/layout.module';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { ParamModule } from '@monsido/core/param/param.module';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ServicesModule } from '@monsido/services/services.module';
import {
    TableModule,
    LayoutModule,
    TranslateModule,
    IconsModule,
    TableSearchModule,
    DialogService,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { PLANS_STATES } from './plans.route';
import { PlansComponent } from './plans.component';
import { PlansService } from './plans.service';
import { BackendPlansRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-plans.repo';
import { PromptModule } from '../../../external/prompt/prompt.module';
import { SearchService } from '@monsido/pages/backend-admin/data-privacy-checks/helper/search.service';
@NgModule({
    providers: [PlansService, BackendPlansRepo, DialogService, SearchService],
    imports: [
        ParamModule,
        BaseLayoutModule,
        UIRouterModule.forChild({ states: PLANS_STATES }),
        LayoutModule,
        CommonModule,
        ApiModule,
        TranslateModule,
        PipesModule,
        ServicesModule,
        TableModule,
        ConfirmModule,
        PromptModule,
        TableSearchModule,
        IconsModule,
    ],
    declarations: [PlansComponent]
})
export class PlansModule {}
