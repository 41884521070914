import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService } from '@monsido/core/session/session.service';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';
import { MenuItemType, SideMenuComponent } from '../../side-menu.component';

@Component({
    selector: 'mon-side-menu-admin',
    standalone: true,
    imports: [CommonModule, SideMenuComponent],
    templateUrl: './side-menu-admin.component.html',
    styleUrls: ['./side-menu-admin.component.scss'],
})
export class SideMenuAdminComponent implements OnInit {
    agreement: Agreement;
    menu: MenuItemType[];
    constructor (private sessionService: SessionService, private translateService: TranslateService, private monIconsService: MonIconsService) {}

    ngOnInit (): void {
        this.agreement = this.sessionService.agreement;
        if (this.agreement) {
            this.menu = [
                {
                    title: this.translateService.getString('Customers'),
                    icon: this.monIconsService.getIcon(['CUSTOMERS', 'ICON']),
                    base: 'base.admin.customers',
                    href: 'base.admin.customers.index',
                    basic: true,
                },
                {
                    title: this.translateService.getString('Domains'),
                    icon: this.monIconsService.getIcon(['DOMAINS', 'ICON']),
                    base: 'base.admin.domains',
                    href: 'base.admin.domains.index',
                    basic: true,
                },
                {
                    title: this.translateService.getString('Users'),
                    icon: this.monIconsService.getIcon(['USERS', 'ICON']),
                    base: 'base.admin.users',
                    href: 'base.admin.users.index',
                    basic: true,
                },
                {
                    title: this.translateService.getString('Crawl queue'),
                    icon: this.monIconsService.getIcon(['CRAWL_QUEUE', 'ICON']),
                    base: 'base.admin.crawl_queue',
                    href: 'base.admin.crawl_queue.index',
                    basic: true,
                },
                {
                    title: this.translateService.getString('Source code excludes'),
                    icon: this.monIconsService.getIcon(['SOURCE_CODE_EXCLUDES', 'ICON']),
                    base: 'base.admin.source_code_excludes',
                    href: 'base.admin.source_code_excludes.index',
                    basic: true,
                },
                {
                    title: this.translateService.getString('Constraints and excludes'),
                    icon: this.monIconsService.getIcon(['CONSTRAINTS_AND_EXCLUDES', 'ICON']),
                    base: 'base.admin.constraints_and_excludes',
                    href: 'base.admin.constraints_and_excludes.index',
                    basic: true,
                },
            ];
        }
    }
}
