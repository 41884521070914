<form name="form" #importDomainsForm="ngForm" class="form-horizontal">
    <ng-container *ngIf="(stepObs | async) as step">
        <div class="mon-dialog-header">
            <mon-page-header header="{{'Import domains' | translate }}"></mon-page-header>
        </div>
        <div class="mon-dialog-body-with-footer" >
            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 1">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Upload CSV</h3>
                </div>
                <div class="card-body">
                    <mon-form-domain-upload (nextFile)="onUploadDone($event)"></mon-form-domain-upload>
                </div>
            </div>

            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 2">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Select import</h3>
                </div>
                <div class="card-body">
                    <mon-form-domain-setup [data]="data" (update)="onUpdateSelected($event)"></mon-form-domain-setup>
                </div>
            </div>

            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 3">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Setup domains</h3>
                </div>

                <div class="card monsido-panel mx-6 mt-6">
                    <div class="card-header">
                        <h3 class="font-weight-300" translate>Scan</h3>
                    </div>
                    <div class="card-body">
                        <mon-form-domain-scan [monDomain]='domain' [monForm]='importDomainsForm' [monIsBackendAdmin]="monIsBackendAdmin"></mon-form-domain-scan>
                    </div>
                </div>
                <div class="card monsido-panel mx-6 mt-6">
                    <div class="card-header">
                        <h3 class="font-weight-300" translate>Features</h3>
                    </div>
                    <div class="card-body">
                        <mon-form-domain-features [monCustomer]='customer' [monDomain]='domain' [monIsBackendAdmin]="monIsBackendAdmin" [monForm]='importDomainsForm'></mon-form-domain-features>
                    </div>
                </div>
            </div>

            <div class="card monsido-panel mt-3 mx-3" *ngIf="step === 4">
                <div class="card-header">
                    <h3 class="font-weight-300" translate>Confirm</h3>
                </div>
                <div class="card-body">
                    Import {{ data.data.length - 1 }} domains?
                    <mon-panel header="{{'Features' | translate}}">
                        <div class="card-body" slot="mon-card-body">
                            <div class="font-size-1-1">
                                <div class="row" *ngFor="let item of domain.features | keyvalue">
                                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize">
                                        {{ item.key | monUnderscoreless }}
                                    </div>
                                    <div class="col-sm-26 col-lg-36 text-grey">
                                        <span *ngIf="!isBoolean(item.value)">{{ item.value | toString }}</span>
                                        <mon-icon *ngIf="item.value && isBoolean(item.value)" [icon]="['CUSTOMERS', 'FEATURES', 'ACTIVE']"></mon-icon>
                                        <mon-icon *ngIf="!item.value && isBoolean(item.value)" [icon]="['CUSTOMERS', 'FEATURES', 'INACTIVE']"></mon-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mon-panel>

                    <mon-panel header="{{'Scan' | translate}}">
                        <div class="card-body" slot="mon-card-body">
                            <div class="font-size-1-1">
                                <div class="row" *ngFor="let item of domain.scan | keyvalue">
                                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize">
                                        {{ item.key | monUnderscoreless }}
                                    </div>
                                    <div class="col-sm-26 col-lg-36 text-grey">
                                        <span *ngIf="!isBoolean(item.value)">{{ item.value | toString }}</span>
                                        <mon-icon *ngIf="item.value && isBoolean(item.value)" [icon]="['CUSTOMERS', 'SCAN', 'ACTIVE']"></mon-icon>
                                        <mon-icon *ngIf="!item.value && isBoolean(item.value)" [icon]="['CUSTOMERS', 'SCAN', 'INACTIVE']"></mon-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mon-panel>
                </div>
            </div>
        </div>
        <div class="mon-dialog-footer text-right">
            <button
                class="btn btn-secondary" [disabled]="saving || importDomainsForm.invalid"
                *ngIf="step === 2 || step === 3"
                (click)="incrementStep()"
                translate>
                Next
            </button>
            <button class="btn btn-secondary" (click)='submit()' *ngIf="step === 4" data-test="import-domains-button">
                <span *ngIf="!saving" translate>Import domains</span>
                <mon-icon *ngIf="saving" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
            </button>
        </div>
    </ng-container>
</form>
