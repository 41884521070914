import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULTS } from '@monsido/core/constants/defaults.constant';
import {
    ToastService,
    TranslateService,
    DialogService,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { StateService, UrlService, StateParams, TransitionService } from '@uirouter/core';
import { Job } from '@monsido/modules/models/api/job';
import { JobService } from '@monsido/modules/job/job.service';
import { JOB_TYPES } from '@monsido/modules/job/job.constant';
import { User } from '@monsido/modules/models/api/user';
import { Domain } from '@monsido/modules/models/api/domain';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { GetUsersParamsInterface } from './tabs/users-tab/users-tab.component';
import { FormBackendAdminUserComponent } from '@monsido/forms/backend_admin/customer-user/user.component';
import { GetDomainsParamsInterface } from '@monsido/pages/backend-admin/customers/show/tabs/domains-tab/domains-tab.component';
import { FormBackendAdminCustomerComponent } from '@monsido/forms/backend_admin/customer/customer.component';
import { FormImportDictionaryComponent } from '@monsido/forms/import-dictionary/import-dictionary.component';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { ImportDomainsService } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.service';
import { Customer } from '@monsido/modules/models/api/customer';
import { FormImportDomainsComponent } from '@monsido/forms/backend_admin/domain/import-domains/import-domains.component';
import { FormDomainComponent } from '@monsido/forms/domain/domain.component';
import { FormImportConfirmedSpellingErrorsComponent } from '@monsido/forms/import-confirmed-spelling-errors/import-confirmed-spelling-errors.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { FormPdfClarityComponent } from '@monsido/forms/pdf-clarity/pdf-clarity.component';
import { ParamService } from '@monsido/core/param/param.service';
import { CUSTOMER_TABS } from './show.constant';
import { ResellersRepo } from '../../../../../app/services/api/backend_admin/resellers-repo';

@Component({
    selector: 'mon-backend-customer-show',
    templateUrl: 'show.html',
})
export class BackendAdminCustomerShowComponent extends BaseApiComponent implements OnInit, OnDestroy {
    customer: Customer;
    activeTab: string;
    jobs: Job;
    tabs: Record<string, string>[];

    users: CollectionInterface<User>;
    userPage = 1;
    userPageSize = DEFAULTS.API.PAGE_SIZE;
    userSearch: string;
    userLoading = true;

    domains: CollectionInterface<Domain> = [];
    domainPage = 1;
    domainPageSize = DEFAULTS.API.PAGE_SIZE;
    domainSearch: string;
    domainLoading: boolean;

    csvImportSpellingTooltip: string;
    csvImportDictionaryTooltip: string;

    computedCsvImportSpellingTooltip: string;
    computedCsvImportDictionaryTooltip: string;
    unsubscribeTransition: unknown;

    constructor (
        private translateService: TranslateService,
        private backendCustomerRepo: BackendCustomerRepo,
        private backendResellerRepo: ResellersRepo,
        private stateService: StateService,
        private jobService: JobService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private state: StateService,
        private importDomService: ImportDomainsService,
        private modalService: NgbModal,
        private paramsService: ParamService,
        $location: UrlService,
        private transitionService: TransitionService,
    ) {
        super($location);
    }

    async ngOnInit (): Promise<void> {
        const { users, domains, defaultTab } = CUSTOMER_TABS;
        this.tabs = [
            {
                faIcon: defaultTab.icon,
                name: this.translateService.getString(defaultTab.name),
                value: defaultTab.value,
            },
            {
                faIcon: users.icon,
                name: this.translateService.getString(users.name),
                value: users.value,
            },
            {
                faIcon: domains.icon,
                name: this.translateService.getString(domains.name),
                value: domains.value,
            },
        ];
        this.activeTab = this.getActiveTab(this.stateService?.params);
        this.setFromParams(this.stateService?.params);

        this.csvImportSpellingTooltip = this.translateService.getString('CSV import confirmed spelling errors');
        this.csvImportDictionaryTooltip = this.translateService.getString('CSV import dictionary');

        await this.getPage();

        this.jobs = this.jobService.jobViewModelWrapper();

        const customerId = this.customer?.id;
        if (customerId) {
            this.computeTooltips(customerId);

            this.jobService.watchJobWithType(JOB_TYPES.CUSTOMER).forEach((job) => {
                if (job.ref && job.ref === customerId) {
                    job.obs.subscribe((newJob) => {
                        this.jobs.watchJobHandle(newJob, job.ref);
                        this.computeTooltips(customerId);
                    });
                }
            });
        }

        this.loadLocations(false);

        this.unsubscribeTransition = this.transitionService.onSuccess(
            { retained: 'base.backend_admin.customers.show' },
            (transition) => {
                const { search, plan_type: planType, page, page_size: pageSize } = transition.targetState().params();
                if (planType && (this.activeTab !== planType || this.userSearch !== search)) {
                    const detailParams: GetDomainsParamsInterface | GetUsersParamsInterface = {
                        search, page, pageSize: pageSize,
                    };
                    if (this.activeTab === CUSTOMER_TABS.users.value && this.userSearch !== search) {
                        this.getUsers(detailParams);
                    }
                    if (this.activeTab === CUSTOMER_TABS.domains.value && this.userSearch !== search) {
                        this.getDomains(detailParams);
                    }
                    this.activeTab = planType;
                    this.userSearch = search;
                }
            });
    }

    ngOnDestroy (): void {
        if (typeof this.unsubscribeTransition === 'function') {
            this.unsubscribeTransition();
        }
    }

    async getPage (): Promise<void> {
        const customerId = this.stateService?.params?.customer_id;
        if (!customerId) {
            return;
        }
        try {
            this.customer = await this.backendCustomerRepo.get(customerId);
        } catch (e) {
            this.customer = null;
        }
    }

    computeTooltips (customerId: number): void {
        this.computedCsvImportSpellingTooltip = this.getCsvImportButtonTooltip(this.csvImportSpellingTooltip, customerId);

        this.computedCsvImportDictionaryTooltip = this.getCsvImportButtonTooltip(this.csvImportDictionaryTooltip, customerId);
    }

    async getUsers (getUsersParams: GetUsersParamsInterface): Promise<void> {
        const { search, page, pageSize } = getUsersParams;
        this.userSearch = search;
        this.userPage = page;
        this.userPageSize = pageSize;
        const params = {
            page: page,
            page_size: pageSize,
            plan_type: this.activeTab,
            search,
        };

        this.userLoading = true;
        this.paramsService.setParams(params);

        const customerId = this.customer?.id;
        if (customerId) {
            try {
                this.users = await this.backendCustomerRepo.getAllUsers(customerId, params);
            } finally {
                this.userLoading = false;
            }
        }
    }

    async getDomains (getDomainsParams: GetDomainsParamsInterface): Promise<void> {
        const { search, page, pageSize } = getDomainsParams;
        this.domainSearch = search;
        this.domainPage = page;
        this.domainPageSize = pageSize;
        const params = {
            page,
            page_size: pageSize,
            search,
            plan_type: this.activeTab,
        };

        this.domainLoading = true;
        this.paramsService.setParams(params);

        const customerId = this.customer?.id;
        if (customerId) {
            try {
                this.domains = await this.backendCustomerRepo.getAllDomains(customerId, params);
            } finally {
                this.domainLoading = false;
            }
        }
    }

    onTabChange (tab: string): void {
        this.activeTab = tab;
        const params = {
            page: this.userPage,
            page_size: this.userPageSize,
            search: this.userSearch,
            plan_type: this.activeTab,
        };

        this.paramsService.setParams(params);
    }

    getCsvImportButtonTooltip (orgTooltipText: string, customerId: number): string {
        if (this.customer && this.jobs.hasJob(customerId)) {
            return `${orgTooltipText} (${this.jobs.jobTooltip(customerId)})`;
        } else {
            return orgTooltipText;
        }
    }

    importDictionary (): void {
        const customerId = this.customer?.id;

        if (!customerId || this.jobs.hasJob(customerId)) {
            return;
        }

        const dialogRef = this.dialogService.open(FormImportDictionaryComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (job: JobInterface) => {
                if (!job) {
                    return;
                }
                if (customerId) {
                    this.jobService.watchJob(job, JOB_TYPES.CUSTOMER, customerId).subscribe((newJob) => {
                        this.jobs.watchJobHandle(newJob, customerId);
                        this.computeTooltips(customerId);
                    });
                }
            },
        });
        dialogRef.componentInstance.customerId = customerId;
    }

    importConfirmedSpellingErrors (): void {
        const customerId = this.customer?.id;

        if (!customerId || this.jobs.hasJob(customerId)) {
            return;
        }

        const dialogRef = this.dialogService.open(FormImportConfirmedSpellingErrorsComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (job: JobInterface) => {
                if (!job) {
                    return;
                }
                if (customerId) {
                    this.jobService.watchJob(job, JOB_TYPES.CUSTOMER, customerId).subscribe((newJob) => {
                        this.jobs.watchJobHandle(newJob, customerId);
                        this.computeTooltips(customerId);
                    });
                }
            },
        });

        dialogRef.componentInstance.monCustomerId = customerId;
    }

    createUser (): void {
        const customerId = this.customer?.id;
        if (!customerId) {
            return;
        }
        const dialogRef = this.dialogService.open(FormBackendAdminUserComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                return this.getUsers({
                    search: this.userSearch,
                    page: this.userPage,
                    pageSize: this.userPageSize,
                });
            },
        });
        dialogRef.componentInstance.user = new User();
        dialogRef.componentInstance.customerId = customerId;
    }

    createDomain (): void {
        const dialogRef = this.dialogService.open(FormDomainComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
            cb: async () => {
                this.domainPage = 1;
                await this.getUsers({
                    search: this.userSearch,
                    page: this.userPage,
                    pageSize: this.userPageSize,
                });
                await this.getDomains({
                    search: this.domainSearch,
                    page: this.domainPage,
                    pageSize: this.domainPageSize,
                });
            },
        });
        dialogRef.componentInstance.monDomain = new Domain();
        dialogRef.componentInstance.monCustomer = this.customer;
        dialogRef.componentInstance.monIsBackendAdmin = true;
    }

    importDomain (): void {
        const dialogRef = this.dialogService.open(FormImportDomainsComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => {
                this.importDomService.resetSteps();
                return this.getDomains({
                    search: this.domainSearch,
                    page: this.domainPage,
                    pageSize: this.domainPageSize,
                });
            },
        });
        dialogRef.componentInstance.customer = this.customer;
        dialogRef.componentInstance.monIsBackendAdmin = true;
    }

    onDeleteCustomer (result: string) {
        return (customer: Customer): void => {
            if (Number(customer?.id) === Number(result)) {
                this.backendResellerRepo.destroyCustomer(customer?.reseller?.id, customer?.id).then(() => {
                    this.toastService.success(this.translateService.getString('Customer is deleted'));
                    this.state.go('base.backend_admin.customers.all');
                });
            } else {
                this.toastService.error(this.translateService.getString('Wrong ID'));
            }
        };
    }

    editCustomer (): void {
        const dialogRef = this.dialogService.open(FormBackendAdminCustomerComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
            cb: async (updatedCustomer: Customer) => {
                if (updatedCustomer) {
                    this.customer = updatedCustomer;

                    await this.getUsers({
                        search: this.userSearch,
                        page: this.userPage,
                        pageSize: this.userPageSize,
                    });
                    await this.getDomains({
                        search: this.domainSearch,
                        page: this.domainPage,
                        pageSize: this.domainPageSize,
                    });
                }
            },
        });
        dialogRef.componentInstance.customer = this.customer;
    }

    openToken (): void {
        const modalRef = this.modalService.open(FormPdfClarityComponent);
        modalRef.componentInstance.customer = this.customer;

        modalRef.result.then((customer) => {
            this.customer = customer;
        }, noop);
    }

    private getActiveTab (params: StateParams): string {
        const planType = params?.plan_type;
        return planType ? planType : CUSTOMER_TABS.defaultTab.value;
    }

    private setFromParams (params: StateParams): void {
        const planType = params?.plan_type || CUSTOMER_TABS.defaultTab.value;
        const search = params?.search || '';
        const pageSize = params?.page_size || 10;
        const page = params?.page || 1;

        switch (planType) {
            case CUSTOMER_TABS.domains.value:
                this.domainSearch = search;
                this.domainPageSize = pageSize;
                this.domainPage = page;
                break;
            case CUSTOMER_TABS.users.value:
                this.userSearch = search;
                this.userPageSize = pageSize;
                this.userPage = page;
                break;
            default:
                this.paramsService.setParams({
                    plan_type: planType,
                });
                break;
        }
    }

}
