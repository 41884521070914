import { Injectable } from '@angular/core';
import { LanguageType, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TRANSLATIONS } from '@monsido/core/constants/translations.constant';

@Injectable({
    providedIn: 'root',
})
export class TranslationHelper {
    constructor (private translateService: TranslateService) {}

    pickLanguage (language: string): void {
        const trans: LanguageType = (TRANSLATIONS[language] || this.translateService.baseLanguage) as LanguageType;
        this.translateService.setCurrentLanguage(trans);
    }
}
