import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { UserImportCSVData, UserImportSelectedModel } from '../../setup/setup.component';

@Component({
    selector: 'mon-user-import-confirm',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
    ],
    templateUrl: './user-import-confirm.component.html',
})
export class UserImportConfirmComponent {
    @Input() data: UserImportCSVData;
    @Input() selected: UserImportSelectedModel;
}
