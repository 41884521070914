import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';

import { Customer } from '@monsido/modules/models/api/customer';
import { environment } from '../../../../../environments/environment';
import { SearchService } from '@monsido/pages/backend-admin/support/search/search.service';
import { Domain } from '@monsido/modules/models/api/domain';
import { ParamService } from '@monsido/core/param/param.service';

interface env {
    env: string;
    url: string;
    customers: Customer[];
    domains: Domain[];
}

@Component({
    selector: 'mon-search-page',
    templateUrl: 'search.html',
})
export class SearchPageComponent implements OnInit {
    envs: Array<env> = [];
    customer: Customer | void;
    domain: Domain | void;
    searching: boolean = false;
    search_string: string;
    loading: boolean = false;
    constructor (private $location: UrlService, private searchService: SearchService, private paramService: ParamService) {}

    async ngOnInit (): Promise<void> {
        this.search_string = this.$location.search()['search'];

        if (this.search_string) {
            await this.search();
        }
    }

    canSearch (): string {
        return this.search_string;
    }

    async goToCustomer (url: string, customer: Customer): Promise<void> {
        await this.searchService.redirectToCustomer(customer, url);
    }
    async goToDomain (url: string, domain: Domain): Promise<void> {
        await this.searchService.redirectToDomain(domain, url);
    }

    async search (): Promise<void> {
        let env: { name: string; url: string };
        this.customer = null;
        this.domain = null;
        this.envs = [];
        let singleEnv: env;
        this.searching = true;

        this.paramService.setParams({
            search: this.search_string,
        });

        for (let i = 0; i < environment.apiEnvs.length; i++) {
            env = environment.apiEnvs[i];
            singleEnv = {
                env: env.name,
                url: env.url,
                customers: [],
                domains: [],
            };

            const customers = await this.searchService.searchCustomer(this.search_string, env.url).catch(() => {});
            if (customers) {
                singleEnv.customers = customers;
            }
            const domains = await this.searchService.searchDomain(this.search_string, env.url).catch(() => {});
            if (domains) {
                singleEnv.domains = domains;
            }
            this.envs.push(singleEnv);
        }
        this.searching = false;
    }
}
