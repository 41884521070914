<mon-page-header
    [monIcon]="['SEARCH', 'ICON']"
    header="{{'Search'}}">
</mon-page-header>

<form name="form" class="form-horizontal" #form="ngForm" (ngSubmit)="search()">
    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-input [(ngModel)]="customer_id"
                                  monLabel="{{ 'Customer id' | translate }}"
                                  monPlaceholder="{{ 'Customer id' | translate }}"
                                  [type]="'text'"
                                  #customerId="ngModel"
                                  [name]="'customerId'"
                                  required>
            </mon-form-field-input>
            <mon-form-field-errors
                [showError]="customerId.control.touched && customerId.control.invalid"
                [errors]="customerId.control.errors">
            </mon-form-field-errors>
        </div>
    </div>
    <div class="row">
        <div class="col-48 col-md-48">
            <mon-form-field-input [(ngModel)]="domain_id"
                                  #domainId="ngModel"
                                  monLabel="{{ 'Domain id' | translate }}"
                                  monPlaceholder="{{ 'Domain id' | translate }}"
                                  [type]="'text'"
                                  [name]="'domainId'"
                                  required>
            </mon-form-field-input>
            <mon-form-field-errors
                [showError]="domainId.control.touched && domainId.control.invalid"
                [errors]="domainId.control.errors">
            </mon-form-field-errors>
        </div>
    </div>
    <div class="col-48 col-md-48 pr-0">
        <button class="btn btn-secondary pull-right" [disabled]="searching || form.invalid" type="submit">
            <span [hidden]="searching" translate>Search</span>
            <mon-icon [hidden]="!searching"
                  [spin]="searching"
                  [icon]="['SPINNER', 'ICON']">
            </mon-icon>
        </button>
    </div>
</form>

<div class="card monsido-panel mt-8">
    <div class="card-body">
        <div class="table-container-body">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th translate>Environment</th>
                        <th translate>Customer</th>
                        <th translate>Domain</th>
                        <th translate>Go to</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let env of this.envs">
                        <tr>
                            <td>
                                {{ env.env}}
                            </td>
                            <td>
                                <ng-container *ngIf="env.customer">
                                    {{ env.customer.name }}
                                </ng-container>
                                <ng-container *ngIf="!env.customer">
                                    <span translate>Customer not found</span>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="env.domain">
                                    {{ env.domain.url }}
                                </ng-container>
                                <ng-container *ngIf="!env.domain">
                                    <span translate>Domain not found</span>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="
                                    (env.customer && !env.domain) ||
                                    (env.domain && !env.customer) ||
                                    (env.customer && env.domain &&
                                    (env.customer.id === env.domain.customer.id))">
                                    <button type="button"
                                            (click)="goTo(env.url, env.customer, env.domain)"
                                            class="btn btn-secondary">
                                        <span translate>Go to </span>
                                        <span class="fas fa-arrow-right" aria-hidden="true"></span>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

