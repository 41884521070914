import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { Injectable } from '@angular/core';
import { TransitionService, UrlService } from '@uirouter/core';
import { DataPrivacyChecksService } from './data-privacy-checks.service';
import { DataPrivacyCheckInterface } from '@monsido/modules/models/api/interfaces/data-privacy-check.interface';
import { ParamService } from '@monsido/core/param/param.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';

@Component({
    selector: 'mon-data-privacy-checks',
    templateUrl: 'data-privacy-checks.html',
})
@Injectable()
export class DataPrivacyChecksComponent extends BaseApiComponent implements OnInit, OnDestroy {
    checks: DataPrivacyCheckInterface[] = [];
    orgChecks: DataPrivacyCheckInterface[] = [];
    search: string = '';
    checkStatus: string;
    activeTab: string;
    loading: boolean = false;
    tabs: Array<Record<string, string>> = [
        {
            name: this.translateService.getString('Approved'),
            value: 'true',
        },
        {
            name: this.translateService.getString('Pending'),
            value: 'false',
        },
    ];
    unsubscribeTransition: unknown;

    constructor (
        private translateService: TranslateService,
        private dataPrivacyChecksService: DataPrivacyChecksService,
        private paramService: ParamService,
        $location: UrlService,
        private transitionService: TransitionService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.urlParams = [
            {
                name: 'search',
                type: 'string',
                default: null,
                propertyName: 'search',
            },
            {
                name: 'approved',
                type: 'string',
                default: this.tabs[0].value,
                propertyName: 'activeTab',
            },
        ];

        this.loadLocations();
        this.unsubscribeTransition = this.transitionService.onSuccess(
            { retained: 'base.backend_admin.data_privacy_checks.index' },
            (transition) => {
                const { search, approved } = transition.targetState().params();
                if (approved && (this.activeTab !== approved || this.search !== search)) {
                    this.activeTab = approved;
                    this.search = search;
                    this.getPage();
                }
            });
    }

    ngOnDestroy (): void {
        if (typeof this.unsubscribeTransition === 'function') {
            this.unsubscribeTransition();
        }
    }

    onSearchChecks (search: string): void {
        this.search = search;
        this.setQueryParams();
        this.setChecks();
    }

    getPage (): void {
        const params = {
            page: 1,
            page_size: 0,
            approved: this.activeTab,
        };

        if (this.activeTab === 'true') {
            this.checkStatus = this.translateService.getString('approved');
        } else {
            this.checkStatus = this.translateService.getString('pending');
        }

        this.setQueryParams();

        this.loading = true;
        // prettier-ignore
        this.dataPrivacyChecksService.getAll(params as unknown as HttpParams).then(
            (checks: DataPrivacyCheckInterface[]) => {
                this.orgChecks = checks.slice();
                this.setChecks();
                this.loading = false;
            },
            (res) => {
                this.loading = res.status === -1 && res.xhrStatus === 'abort';
            },
        );
    }

    getGroupName (group: string): string {
        return this.dataPrivacyChecksService.getGroupName(group);
    }

    getPriorityLevel (check: DataPrivacyCheckInterface): string {
        return this.dataPrivacyChecksService.getPriorityLevel(check);
    }

    getRegionNames (regions: string[]): string {
        return this.dataPrivacyChecksService.getRegionNames(regions);
    }

    tabChange (tab: string): void {
        if (tab !== this.activeTab) {
            this.activeTab = tab;
            this.getPage();
        }
    }

    updateApprovalstatus (check: DataPrivacyCheckInterface): void {
        if (check) {
            this.dataPrivacyChecksService.updateApprovalStatus(check).then(
                () => {
                    this.getPage();
                },
                () => {},
            );
        }
    }

    editCheck (e: MouseEvent, check: DataPrivacyCheckInterface): void {
        e.preventDefault();
        this.dataPrivacyChecksService.openDialog(check, (forceUpdate) => {
            if (forceUpdate) {
                this.getPage();
            }
        });
    }

    private setQueryParams (): void {
        this.paramService.setParams({
            approved: this.activeTab,
            search: this.search,
        });
    }

    private setChecks (): void {
        this.checks = this.dataPrivacyChecksService.getFilteredChecks(this.orgChecks, this.search);
    }
}
