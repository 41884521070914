import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { Domain } from '@monsido/modules/models/api/domain';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { FormDomainComponent } from '@monsido/forms/domain/domain.component';
import { DialogService, DialogSize, LayoutModule, TableModule, ToastService, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { StateService } from '@uirouter/core';
import { CustomerRepo } from 'app/services/api/admin/customer-repo';
import { BackendAdminResellerShowComponent } from 'app/pages/backend_admin/resellers/show/backend-admin-reseller-show/backend-admin-reseller-show.component';
import { FormBackendAdminPageAssistComponent } from '@monsido/forms/backend_admin/page-assist/page-assist.component';
import { Customer } from '@monsido/modules/models/api/customer';
import { PageFixDomainSettingsComponent } from '@monsido/forms/backend_admin/page-fix-domain-settings/page-fix-domain-settings.component';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { PromptModule } from 'ng2/external/prompt/prompt.module';
import { UIRouterModule } from '@uirouter/angular';

@Component({
    selector: 'mon-domains-table',
    standalone: true,
    imports: [
        CommonModule,
        ConfirmModule,
        PromptModule,
        LayoutModule,
        TranslateModule,
        NgbTooltipModule,
        MonIconsPipe,
        TableModule,
        UIRouterModule,
    ],
    templateUrl: './domains-table.component.html',
    styleUrls: ['./domains-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainsTableComponent {

    @Input() customer: Customer;
    @Input() domains: Domain[];
    @Input() isAdmin: boolean = false;
    @Input() showCustomer: boolean = false;
    @Output() getPage: EventEmitter<void> = new EventEmitter();

    constructor (
        private monEventService: MonEventService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private state: StateService,
        private customerRepo: CustomerRepo,
        private dialogService: DialogService,
    ) {}

    onEditDomain (domain: Domain): void {
        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: FormDomainComponent,
            data: {
                monDomain: domain,
            },
            dialogOptions: {
                size: 'sm',
                cb: () => this.getPage.emit(),
            },
        });
    }

    deleteDomain (domain: Domain, result: string): void {
        if (Number.isSafeInteger(domain.id) && Number.isSafeInteger(domain.customer_id)) {
            if (domain.id === Number(result)) {
                this.onDeleteDomain(domain);
            } else {
                this.toastService.error(this.translateService.getString('ID was not correct'));
            }
        } else {
            this.toastService.error(this.translateService.getString('Domain or customer_id is not set on ' + domain.title));
        }
    }

    goToDomain (domain: Domain): void {
        const params = {
            customer_id: domain.customer_id,
            domain_id: domain.id,
        };
        this.state.go('base.admin.customers.info.domain', params);
    }

    onDeleteDomain (domain: Domain): void {
        this.customerRepo.destroyDomain(domain.customer_id, domain.id).then(() => {
            this.toastService.success(this.translateService.getString('Domain is deleted'));
            this.getPage.emit();
        }, () => {});
    }

    onRescan (domain: Domain): void {
        this.customerRepo.createDomainCrawls(domain.customer_id, domain.id).then(() => {
            this.toastService.success(this.translateService.getString('Rescan started'));
            this.getPage.emit();
        }, () => {});
    }

    onOpenReseller (domain: Domain): void {
        if (domain.customer.reseller.id != null) {
            const params = {
                size: 'lg' as DialogSize,
                data: {
                    reseller: domain.customer.reseller,
                },
                cb: (): void => {
                    this.getPage.emit();
                },
            };

            this.dialogService.open(BackendAdminResellerShowComponent, params);
        }
    }

    cloneDomain (domain: Domain): void {
        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: FormDomainComponent,
            data: {
                monDomain: domain.clone(),
            },
            dialogOptions: {
                size: 'sm',
                cb: () => this.getPage.emit(),
            },
        });
    }

    openPageAssist (domain: Domain): void {
        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: FormBackendAdminPageAssistComponent,
            data: {
                domain: domain,
                saveToAPI: true,
                customer: domain.customer,
            },
            dialogOptions: {
                size: 'sm',
                cb: () => this.getPage.emit(),
            },
        });
    }

    hasPageAssist (domain: Domain): boolean {
        if (this.customer) {
            return this.customer.plan_traits.page_assist && domain.features.page_assist;
        } else if (domain.customer?.plan_traits) {
            return domain.customer.plan_traits.page_assist && domain.features.page_assist;
        }

        return false;
    }

    openPageFix (domain: Domain): void {
        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: PageFixDomainSettingsComponent,
            data: {
                customer: this.customer || domain.customer,
                domain: domain,
                saveToAPI: true,
                isAdmin: false,
            },
            dialogOptions: {
                size: 'md',
                cb: () => {
                    this.getPage.emit();
                },
            },
        });
    }

    domainHasAccessibility (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.accessibility && customer.plan_traits.accessibility;
        }
        return false;
    }

    domainHasStatistics (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.statistics && customer.plan_traits.statistics;
        }
        return false;
    }

    domainHasReadability (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.readability_test && customer.plan_traits.readability;
        }
        return false;
    }

    domainHasPageFix (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.page_fix && customer.plan_traits.page_fix;
        }
        return false;
    }

    domainHasPageAssist (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.page_assist && customer.plan_traits.page_assist;
        }
        return false;
    }

    getUserCount (domain: Domain): number {
        return domain.domain_users.reduce((num, user) => {
            if (user.visible) {
                num++;
            }
            return num;
        }, 0);
    }

    hasMaxScannedPages (domain: Domain): boolean {
        return (
            !isNaN(domain.scan.max_scanned_pages) &&
            isFinite(domain.scan.max_scanned_pages) &&
            typeof domain.scan.max_scanned_pages === 'number'
        );
    }

    getPagesLeft (domain: Domain): number {
        return (
            domain.scan.max_scanned_pages -
                (domain.crawled_pages ? domain.crawled_pages : 0) -
                (domain.crawl_history ? domain.crawl_history.documents_count : 0) || 0
        );
    }

    hasConstraintsOrExcludes (domain: Domain): boolean {
        return domain.path_constraints.length + domain.link_excludes.length > 0;
    }

    private getCustomer (domain: Domain): Customer {
        if (this.customer) {
            return this.customer;
        } else if (domain.customer) {
            return domain.customer;
        }

        return null;
    }

}
