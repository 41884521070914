import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { cloneDeep } from 'lodash';
import { BackendAdminCustomersRepo } from 'app/services/api/backend_admin/backend-admin-customers-repo';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { MonPromptService, ToastService, TranslateService, TranslateModule, LayoutModule, ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';
import { FormBackendAdminUserBasicComponent } from '../steps/form-backend-admin-user-basic/form-backend-admin-user-basic.component';
import { FormsModule, NgForm } from '@angular/forms';
import { Reseller, UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';

@Component({
    selector: 'mon-form-backend-admin-user',
    standalone: true,
    imports: [CommonModule, LayoutModule, TranslateModule, FormBackendAdminUserBasicComponent, FormsModule],
    templateUrl: './form-backend-admin-user.component.html',
})
export class FormBackendAdminUserComponent implements OnInit {
    @Input() user: UserInterface;
    @Input() customerId: number;
    @Input() reseller: Reseller;
    @Input() monDialogId: number;
    @ViewChild('userForm', { static: false }) userForm: NgForm;
    newUser: UserInterface;
    selectedReseller: Reseller;
    saving: boolean = false;
    basicUserFormValid: boolean;
    spinnerClass: string = 'fa-spin fa-fw ' + this.monIconsService.getIcon(['SPINNER', 'ICON']);

    constructor (
        private backendAdminCustomersRepo: BackendAdminCustomersRepo,
        private resellersRepo: ResellersRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private activeDialog: ActiveDialog,
        private monIconsService: MonIconsService,
        private promptService: MonPromptService,
    ) {}

    ngOnInit (): void {
        this.newUser = {
            id: null,
            email: null,
            first_name: null,
            last_name: null,
            locale: null,
            social_media: null,
            created_at: null,
            updated_at: null,
        };
        if (this.user) {
            this.newUser = cloneDeep(this.user);
        }
        this.selectedReseller = null;
        this.saving = false;

        if (!this.user) {
            this.user = {
                id: null,
                email: null,
                first_name: null,
                last_name: null,
                locale: null,
                social_media: null,
                created_at: null,
                updated_at: null,
                customer_admin: false,
            };
        }
    }

    onUserFormValidityChange (isFormValid: boolean): void {
        this.basicUserFormValid = isFormValid;
    }

    submit (): void {
        let promise;
        this.saving = true;
        const passwordValid = this.newUser.password === this.newUser.password_confirmation;
        if (passwordValid) {
            if (this.newUser.id) {
                if (!this.reseller) {
                    promise = this.backendAdminCustomersRepo.updateUser(this.customerId, this.newUser.id, this.newUser);
                } else {
                    promise = this.resellersRepo.updateUser(this.reseller.id, this.newUser);
                }
            } else {
                if (!this.reseller) {
                    promise = this.backendAdminCustomersRepo.createUser(this.customerId, this.newUser);
                } else {
                    promise = this.resellersRepo.createUser(this.reseller.id, this.newUser, undefined);
                }
            }
            promise
                .then(() => {
                    this.close();
                    this.toastService.success(this.translateService.getString('User saved'));
                }, ()=> {})
                .finally(() => {
                    this.saving = false;
                });
        } else {
            this.saving = false;
            this.promptService.alert(this.translateService.getString('Password is not valid'));
        }
    }

    close (): void {
        this.activeDialog.close(this.monDialogId, this.user);
    }

    getSpinner (): string {
        if (this.saving) {
            return 'fa-spin fa-fw ' + this.monIconsService.getIcon(['SPINNER', 'ICON']);
        }
        return '';
    }
}
