<mon-panel-header
    pHeader="{{'All customers domains' | translate}}"
    subHeader="{{'This account has ' | translate}} {{ domains && domains.total }} {{ 'active domains right now' | translate}}">
    <div class="d-flex align-items-center">
        <div class="mr-4">
            <button class="btn btn-secondary py-2 px-3 mr-2"
                    type="button"
                    (click)="importDomains()"
                    [ngbTooltip]="'Import new domains' | translate"
                    [attr.aria-label]="'Import new domains' | translate"
                    data-test="import-new-domains-button">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                <mon-icon [icon]="['ACTIONS', 'IMPORT']" [size]="'lg'"></mon-icon>
            </button>
            <button class="btn btn-secondary py-2 px-3"
                    type="button"
                    (click)="createDomain()"
                    [ngbTooltip]="'Add new domain' | translate"
                    [attr.aria-label]="'Add new domain' | translate"
                    data-test="add-new-domains-button">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                <mon-icon [icon]="['DOMAINS', 'ICON']" [size]="'lg'"></mon-icon>
            </button>
        </div>
        <div>
            <mon-table-search [model]="search" [minSearchLength]="1" (getPage)="onSearchChanged($event)" data-test="search-input"></mon-table-search>
        </div>
    </div>
</mon-panel-header>
<div class="col-lg-48 inner-l-between-small-mini">
    <div class="card-body">
        <div class="col-md-48 col-lg-48 col-xs-48">
            <mon-table-container
                [collection]="domains"
                (pageChangeEvent)="onPageChange($event)"
                (perPageChange)="onPageSizeChange($event)"
                [loading]="loading"
            >
                <div class="table-container-top">
                    <button class="btn btn-secondary"
                            [disabled]="selectedDomains.length === 0"
                            [monConfirm]="'Are you sure you want to delete selected Domains?' | translate"
                            (monConfirmAction)="selectedDomains.length > 0 && deleteDomains()"
                            data-test="delete-selected-domains"
                            translate>
                        Delete selected domains
                    </button>
                </div>
                <div class="table-container-body">
                    <mon-backend-admin-domains-table
                        [monDomains]="domains"
                        [monCustomer]="customer"
                        [monShowCustomer]="false"
                        [monSelectedDomains]="selectedDomains"
                        (monGetPageEvent)="getPage()"
                    ></mon-backend-admin-domains-table>
                </div>
            </mon-table-container>
        </div>

    </div>
</div>
