<mon-panel-header
    pHeader="{{'Users for this customer' | translate}}"
    subHeader="{{'This account has ' | translate}} {{ users && users.total }} {{ 'users right now' | translate}}">
    <div class="d-flex align-items-center">
        <div class="mr-4">
            <button class="btn btn-secondary py-2 px-3"
                    type="button"
                    (click)="createUser()"
                    [attr.aria-label]="'Add new user' | translate"
                    [ngbTooltip]="'Add new user' | translate"
                    data-test="add-new-user-button">
                <mon-icon [icon]="['ACTIONS', 'ADD']"></mon-icon>
                <mon-icon [size]="'lg'" [icon]="['USERS', 'SINGLE_ICON']"></mon-icon>
            </button>
        </div>
        <div>
            <mon-table-search [model]="search" [minSearchLength]="1" (getPage)="onSearchChanged($event)" data-test="search-input"></mon-table-search>
        </div>
    </div>
</mon-panel-header>
<div class="card-body">
    <div class="col-md-48 col-lg-48 col-xs-48">
        <mon-table-container
            [collection]="users"
            (pageChangeEvent)="onPageChange($event)"
            (perPageChange)="onPageSizeChange($event)"
            class="table-vertial-align"
            [loading]="loading">
            <div class="table-container-top">
                <button class="btn btn-secondary"
                        [disabled]="selectedUsers.length === 0"
                        monConfirm="{{'Are you sure you want to delete selected users' | translate}}"
                        (monConfirmAction)="selectedUsers.length > 0 && deleteUsers()"
                        data-test="delete-selected-users-button"
                        translate>
                    Delete selected users
                </button>
            </div>
            <div class="table-container-body">
                <table class="table table-hover">
                <thead>
                <tr>
                    <th class="col-md-2">
                        <mon-table-select-item-all [(selectedItems)]="selectedUsers" [items]="usersArray">
                        </mon-table-select-item-all>
                    </th>
                    <th class="col-sm-12 col-md-12 col-lg-10" translate>User</th>
                    <th class="col-sm-12 col-md-12 col-lg-10" *ngIf="showCustomer" translate>Customer</th>
                    <th class="col-sm-12 col-md-12 col-lg-10" translate>Partner</th>
                    <th class="col-sm-8 col-md-7 col-lg-4" translate>Latest login</th>
                    <th class="col-sm-4 col-md-4 col-lg-2" translate>Role</th>
                    <th class="col-sm-4 col-md-4 col-lg-2" translate>Api key</th>
                    <th class="col-sm-5 col-md-5 col-lg-4 text-right" translate></th>
                    <th class="col-sm-5 col-md-5 col-lg-4 text-right" translate *ngIf="['admin', 'reseller_admin', 'reseller'] | monRole"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of usersArray">
                    <td>
                        <mon-table-select-item class="inline-block" [(selectedItems)]="selectedUsers" [item]="user">
                        </mon-table-select-item>
                    </td>
                    <td>
                        <mon-email-avatar [user]="user"></mon-email-avatar>
                    </td>
                    <td>
                        {{ customer.reseller.name }}
                    </td>
                    <td>
                        <mon-user-date [twoLines]="true" [date]="user.last_login_at" data-test="latest-login"></mon-user-date>
                    </td>
                    <td>
                            <span *ngIf="user.customer_admin" translate>
                                Admin
                            </span>
                            <span *ngIf="user.can_sudo" translate>
                                Sudo able
                            </span>
                    </td>
                    <td>
                        <span *ngIf="user.can_sudo">
                            {{user.api_key}}
                        </span>
                    </td>
                    <td class="text-right">
                        <mon-table-dropdown>
                            <li *ngIf="(['admin', 'reseller_admin', 'reseller'] | monRole)" role="menuitem" data-test="sudo-classic">
                                <a [attr.href]="path + '/sudo/' + user.id + '?customer_id=' + customer.id" target="_blank" aria-describedby="linkOpensInNewTab">
                                    <mon-icon [icon]="['USERS', 'SUDO']"></mon-icon>
                                    <span class="ml-1" translate>Sudo classic</span>
                                </a>
                            </li>
                            <li role="menuitem" class="cursor-pointer" (click)="onEditUser(user)" data-test="edit-button">
                                <a>
                                    <mon-icon [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                                    <span class="ml-1" translate>Edit</span>
                                </a>
                            </li>
                            <li role="menuitem"
                                monConfirm="{{ 'Are you sure you want to delete this customer-user?' | translate }}"
                                (monConfirmAction)="onDeleteUser(user)"
                                class="cursor-pointer"
                                data-test="delete-button">
                                <a>
                                    <mon-icon [icon]="['ACTIONS', 'DELETE']"></mon-icon>
                                    <span class="ml-1" translate>Delete</span>
                                </a>
                            </li>
                        </mon-table-dropdown>
                    </td>
                    <td class="text-right">
                        <mon-sudo-button [user]="user" [customer]="customer" (sudoEvent)="onSudo($event)" data-test="sudo-button"></mon-sudo-button>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
            <hr class="line-thickness-4 outer-t-none"/>
        </mon-table-container>
    </div>
</div>
