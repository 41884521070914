import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveDialog, LayoutModule, ToastService, TranslateModule, TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonFormCustomerDetailComponent } from '../steps/form-customer-detail/mon-form-customer-detail.component';
import { MonFormCustomerPlan } from '../steps/form-customer-plan/form-customer-plan.component';
import { cloneDeep, merge } from 'lodash';
import { CustomerRepo } from 'app/services/api/admin/customer-repo';
import { Customer } from '@monsido/modules/models/api/customer';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';

@Component({
    selector: 'mon-form-customer',
    standalone: true,
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        MonFormCustomerDetailComponent,
        MonFormCustomerPlan,
    ],
    templateUrl: './form-customer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCustomerComponent implements OnInit {

    @Input() customer: Customer;

    saving: boolean = false;
    spinnerIconClassname = 'fa-spin fa-fw ' + this.monIconsService.getIcon(['SPINNER', 'ICON']);
    customerDetailValid: boolean = false;
    customerPlanValid: boolean = false;

    constructor (
        private customerRepo: CustomerRepo,
        private monIconsService: MonIconsService,
        private activeDialog: ActiveDialog,
        private translateService: TranslateService,
        private toastService: ToastService,
    ) {}

    ngOnInit (): void {
        this.customer = merge(this.customer || { settings: { legacy_compliance: false } });
        this.saving = false;
    }

    submit (e: Event): void {
        e.stopImmediatePropagation();
        e.preventDefault();

        if (!this.customerDetailValid || !this.customerPlanValid) {
            return;
        }

        let promise;
        const copy = cloneDeep(this.customer);
        // Remove all plan overrides with null value (While editing a customer)
        if (copy.subscription && copy.subscription.plan_overrides) {
            Object.keys(copy.subscription.plan_overrides).forEach((planOverrideKey) => {
                if (copy.subscription.plan_overrides[planOverrideKey] === null) {
                    delete copy.subscription.plan_overrides[planOverrideKey];
                }
            });
        }

        if (copy.vertical === null) {
            delete copy.vertical;
        }
        this.saving = true;

        if (this.customer.id !== undefined && this.customer.id !== null) {
            promise = this.customerRepo.update(copy);
        } else {
            promise = this.customerRepo.create(copy);
        }

        promise
            .then((data) => {
                this.toastService.success(this.translateService.getString('Customer saved'));
                this.customer = data;
                this.activeDialog.close(this.customer);
            }, () => {})
            .finally(() => {
                this.saving = false;
            });
    }

    updateCustomerDetailValidity (valid: boolean): void {
        this.customerDetailValid = valid;
    }

    updateCustomerPlanValidity (valid: boolean): void {
        this.customerPlanValid = valid;
    }

}
