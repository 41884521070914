<mon-page-header
    [monIcon]="['SEARCH', 'ICON']"
    header="{{'Search'}}">
</mon-page-header>

<form name="form" class="row row-cols-lg-auto g-3 align-items-center" #form="ngForm" (ngSubmit)="search()">
    
    <div class="col-45 col-md-45">
        <mon-form-field-input [(ngModel)]="search_string"
                              monLabel="{{ 'Search' | translate }}"
                              monPlaceholder="{{ 'Customer name or ID, domain name or ID, URL' | translate }}"
                              [type]="'text'"
                              #searchString="ngModel"
                              [name]="'searchString'"
                              data-test="search-input"
                              >
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="searchString.control.touched && searchString.control.invalid"
            [errors]="searchString.control.errors">
        </mon-form-field-errors>
    </div>
    
    <div class="col-3 col-md-3 mt-1">
        <button class="btn btn-secondary pull-right" [disabled]="searching || form.invalid" type="submit" data-test="search-button">
            <span [hidden]="searching" translate>Search</span>
            <mon-icon [hidden]="!searching"
                  [spin]="searching"
                  [icon]="['SPINNER', 'ICON']">
            </mon-icon>
        </button>
    </div>
</form>

<div class="card monsido-panel mt-8">
    <div class="card-body">
        <div class="table-container-body">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th translate>Environment</th>
                        <th translate>Id</th>
                        <th translate>Name</th>
                        <th translate>Url</th>
                        <th translate>Go to</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let env of this.envs">
                        <tr>
                            <td colspan="5">
                                <span [ngClass]="{'fst-italic': env.customers.total === 0}" translate>Customers </span>
                                <ng-container *ngIf="env.customers.length > 0">
                                    <span translate [translate-args]="{length: env.customers.length, total: env.customers.total}">&nbsp;[[length]] out of [[total]]</span>
                                </ng-container>
                                <ng-container *ngIf="env.customers.length === 0">
                                    <span  class="fst-italic" translate>&nbsp; - No matches found</span>
                                </ng-container>
                                <ng-container *ngIf="env.customers.total > 10">
                                    <span translate>&nbsp;(only showing first 10 results - refine your search to reduce results)</span>
                                </ng-container>
                            </td>
                        </tr>
                        <ng-container *ngFor="let customer of env.customers">
                            <tr>
                                <td>
                                    {{ env.env}}
                                </td>
                                <td>
                                    {{ customer.id }}
                                </td>
                                <td>
                                    {{ customer.name }}
                                </td>
                                <td>
                                    N/A
                                </td>
                                <td>
                                    <button type="button"
                                            (click)="goToCustomer(env.url, customer)"
                                            class="btn btn-secondary"
                                            data-test="go-to-button">
                                        <span translate>Go to </span>
                                        <span class="fas fa-arrow-right" aria-hidden="true"></span>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="5">
                                <span [ngClass]="{'fst-italic': env.customers.total === 0}" translate>Domains </span>
                                <ng-container *ngIf="env.domains.length > 0">
                                    <span translate [translate-args]="{length: env.domains.length, total: env.domains.total}">&nbsp;[[length]] out of [[total]]</span>
                                </ng-container>
                                <ng-container *ngIf="env.domains.length === 0">
                                    <span class="fst-italic" translate>&nbsp; - No matches found</span>
                                </ng-container>
                                <ng-container *ngIf="env.domains.total > 10">
                                    <span translate>&nbsp;(only showing first 10 results - refine your search to reduce results)</span>
                                </ng-container>
                            </td>
                        </tr>
                        <ng-container *ngFor="let domain of env.domains">
                            <tr>
                                <td>
                                    {{ env.env}}
                                </td>
                                <td>
                                    {{ domain.id }}
                                </td>
                                <td>
                                    {{ domain.title }}
                                </td>
                                <td>
                                    {{ domain.url }}
                                </td>
                                <td>
                                    <button type="button"
                                            (click)="goToDomain(env.url, domain)"
                                            class="btn btn-secondary"
                                            data-test="go-to-button">
                                        <span translate>Go to </span>
                                        <span class="fas fa-arrow-right" aria-hidden="true"></span>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

