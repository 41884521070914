import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemType, SideMenuComponent } from '../../side-menu.component';
import { MonIconsService } from 'app/blocks/service/mon-icons.service';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'mon-side-menu-backend-admin-settings',
    standalone: true,
    imports: [CommonModule, SideMenuComponent],
    templateUrl: './side-menu-backend-admin-settings.component.html',
    styleUrls: ['./side-menu-backend-admin-settings.component.scss'],
})
export class SideMenuBackendAdminSettingsComponent implements OnInit {
    menu: MenuItemType[];

    constructor (private translateService: TranslateService, private monIconsService: MonIconsService) {}

    ngOnInit (): void {
        this.menu = [
            {
                title: this.translateService.getString('Return to Backend Admin'),
                icon: this.monIconsService.getIcon(['MENUES', 'RETURN']),
                base: 'base.backend_admin.customers',
                href: 'base.backend_admin.customers.all',
                basic: true,
            },
            {
                title: this.translateService.getString('Accessibility checks'),
                icon: this.monIconsService.getIcon(['ACCESSIBILITY_CHECKS', 'ICON']),
                base: 'base.backend_admin.admin.accessibility_checks',
                href: 'base.backend_admin.admin.accessibility_checks.index',
                basic: true,
            },
            {
                title: this.translateService.getString('Data Privacy checks'),
                icon: this.monIconsService.getIcon(['DATA_PRIVACY_CHECKS', 'ICON']),
                base: 'base.backend_admin.admin.data_privacy_checks',
                href: 'base.backend_admin.admin.data_privacy_checks.index',
                basic: true,
            },
        ];

        if (environment.env !== 'production') {
            this.menu.push({
                title: this.translateService.getString('Cookie Repo'),
                icon: this.monIconsService.getIcon(['COOKIES', 'ICON']),
                base: 'base.backend_admin.admin.cookies.repo',
                href: 'base.backend_admin.admin.cookies.repo',
                basic: true,
            });
        }
    }
}
